import { CompositeScreenProps } from '@react-navigation/native';
import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import React from 'react';
import { useQueryClient } from 'react-query';
import { StackScreenProps } from '@react-navigation/stack';
import { fetchMyProfile, useMyProfileQuery } from '../../apis/appsyncApis';
import { sendMail } from '../../apis/mailApis';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { useAuth } from '../../context/AuthContext';
import { useI18n } from '../../context/I18nContext';
import { NavigationDialog } from '../CommonDialogs';
import { webLinkBaseUrl } from '../../../pod-exports.json';
import { InformationStackRouteParams } from '../../navigators/InformationStackNavigator';

type Props = CompositeScreenProps<
  StackScreenProps<InformationStackRouteParams, 'ConfirmAccountDeletionDialog'>,
  AppDrawerScreenProp
>;

export default function CofirmAccoutDeletionDialog({ navigation, route }: Props) {
  const { I18n } = useI18n();
  const { logout } = useAuth();
  const queryClient = useQueryClient();
  const fetchMyProfileQuery = useMyProfileQuery();

  const handleAccountDeletionRequest = async (): Promise<AxiosResponse<any>> => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (e) {
      user = undefined;
    }

    if (!user) {
      // TODO: handle error
    }
    const profile = fetchMyProfileQuery.data?.data?.getMyProfile;
    const signatureName = `${profile?.firstname ?? ''} ${profile?.lastname ?? ''}`;
    const signature = `Sent by ${signatureName}`;
    const subjectPrefix =
      __DEV__ || webLinkBaseUrl === 'https://app.dev.biketti.tech' ? '[Bicyclehut-DEV]' : '[Bicyclehut]';
    const subject = `${subjectPrefix} Account deletion request for ${profile?.phonenumber}`;
    const body = `User ${profile?.phonenumber} (${profile?.firstname ?? ''} ${
      profile?.lastname ?? ''
    }) has requested their account be deleted.`;

    return sendMail(
      subject,
      `${body}

${signature}
`,
    );
  };

  return (
    <NavigationDialog
      title={I18n.t('accountDeletion.dialogTitle')}
      description={I18n.t('accountDeletion.dialogDescription')}
      iconName={'user-unfollow'}
      actions={[
        {
          title: I18n.t('general.cancel'),
          onPress: () => {
            navigation.goBack();
          },
        },
        {
          title: I18n.t('general.ok'),
          onPress: async () => {
            await handleAccountDeletionRequest();
            queryClient.invalidateQueries(fetchMyProfile.id);
            logout();
          },
        },
      ]}
    />
  );
}
