import type maplibregl from 'maplibre-gl';

export type EnrichedMapEvent = maplibregl.MapMouseEvent &
  maplibregl.EventData & {
    mapFeatures: maplibregl.MapboxGeoJSONFeature[];
  };

export type ClickAndEventMonitoringMap = {
  biketti: {
    _clickHandlers: Record<string, (ev: EnrichedMapEvent) => void>;
    _evHandlers: Record<string, (ev: maplibregl.MapMouseEvent & maplibregl.EventData) => void>;
    addClickHandler: (id: string, cb: (ev: EnrichedMapEvent) => void, layers?: string[]) => void;
    removeClickHandler: (id: string) => void;
    addEventHandler: (
      id: string,
      type: 'touchstart' | 'mousedown' | 'wheel',
      cb: (ev: maplibregl.MapMouseEvent & maplibregl.EventData) => void,
    ) => void;
    removeEventHandler: (id: string, type: 'touchstart' | 'mousedown' | 'wheel') => void;
  };
} & maplibregl.Map;

const RegisterClickMonitor = (vars: {}, basicMap: maplibregl.Map, lib: typeof maplibregl, window: Window) => {
  const map = basicMap as ClickAndEventMonitoringMap;
  if (!map.biketti) map.biketti = {} as any;
  if (map.biketti._clickHandlers) return;
  map.biketti._clickHandlers = {};
  map.biketti._evHandlers = {};

  map.biketti.removeEventHandler = function removeMoveHandler(id, type) {
    const realId = `${id}-${type}`;
    if (map.biketti._evHandlers[realId]) {
      map.off(type, map.biketti._evHandlers[realId]);
    }
    delete map.biketti._evHandlers[realId];
  };
  map.biketti.addEventHandler = function addTouchHandler(id, type, realCallback) {
    const realId = `${id}-${type}`;

    map.biketti._evHandlers[realId] = function handleEvt(evt: maplibregl.MapMouseEvent & maplibregl.EventData) {
      /* Events contain a ton of DOM references and other crap we cannot transfer */
      const cleanedEvt: maplibregl.MapMouseEvent & maplibregl.EventData = {
        type: evt.type,
        point: { x: evt.point?.x, y: evt.point?.y } as maplibregl.Point,
        lngLat: {
          lng: evt.lngLat?.lng,
          lat: evt.lngLat?.lat,
        } as maplibregl.LngLat,
        preventDefault: null as unknown as () => void,
        defaultPrevented: evt.defaultPrevented,
        target: null as unknown as maplibregl.Map,
        originalEvent: {
          clientX: evt.originalEvent.clientX,
          clientY: evt.originalEvent.clientY,
          button: evt.originalEvent.button,
          buttons: evt.originalEvent.buttons,
          altKey: evt.originalEvent.altKey,
          ctrlKey: evt.originalEvent.ctrlKey,
          metaKey: evt.originalEvent.metaKey,
          shiftKey: evt.originalEvent.shiftKey,
        } as MouseEvent,
      };
      realCallback(cleanedEvt);
    };
    map.on(type, map.biketti._evHandlers[realId]);
  };

  map.biketti.removeClickHandler = function removeClickHandler(id) {
    if (map.biketti._clickHandlers[id]) map.off('click', map.biketti._clickHandlers[id]);
    delete map.biketti._clickHandlers[id];
  };

  map.biketti.addClickHandler = function addClickHandler(id, realCallback, layers) {
    if (map.biketti._clickHandlers[id]) {
      console.error('Handler already exists ' + id);
      return;
    }
    map.biketti._clickHandlers[id] = function handleClick(evt: maplibregl.MapMouseEvent & maplibregl.EventData) {
      /* Enrich with map data under cursor */
      const { x } = evt.point;
      const { y } = evt.point;
      const opts = layers ? { layers } : undefined;
      const features = map.queryRenderedFeatures(
        [
          [x - 1, y - 1],
          [x + 1, y + 1],
        ],
        opts,
      );

      /* Events contain a ton of DOM references and other crap we cannot transfer */
      const cleanedEvt: EnrichedMapEvent = {
        type: evt.type,
        point: { x, y } as maplibregl.Point,
        lngLat: {
          lng: evt.lngLat.lng,
          lat: evt.lngLat.lat,
        } as maplibregl.LngLat,
        preventDefault: null as unknown as () => void,
        defaultPrevented: evt.defaultPrevented,
        target: null as unknown as maplibregl.Map,
        mapFeatures: features,
        originalEvent: {
          clientX: evt.originalEvent.clientX,
          clientY: evt.originalEvent.clientY,
          button: evt.originalEvent.button,
          buttons: evt.originalEvent.buttons,
          altKey: evt.originalEvent.altKey,
          ctrlKey: evt.originalEvent.ctrlKey,
          metaKey: evt.originalEvent.metaKey,
          shiftKey: evt.originalEvent.shiftKey,
        } as MouseEvent,
      };
      realCallback(cleanedEvt);
    };
    map.on('click', map.biketti._clickHandlers[id]);
    // console.log('Registered click handler ' + id);
  };
};

export default RegisterClickMonitor;
