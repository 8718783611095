/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useMemo } from 'react';
import { TextProps, Text, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';

export type TextVariant = keyof typeof fontVariantStylesheet;

export const fontVariantStylesheet = StyleSheet.create({
  h1: {
    fontSize: 96,
    lineHeight: 112,
    letterSpacing: -1.5,
    textTransform: 'uppercase',
  },
  h2: {
    fontSize: 60,
    lineHeight: 72,
    letterSpacing: -0.5,
    textTransform: 'uppercase',
  },
  h3: {
    fontSize: 48,
    lineHeight: 56,
    textTransform: 'uppercase',
  },
  h4: {
    fontSize: 34,
    lineHeight: 36,
    textTransform: 'uppercase',
  },
  h5: {
    fontSize: 24,
    lineHeight: 30,
    textTransform: 'uppercase',
  },
  h6: {
    fontSize: 20,
    lineHeight: 24,
    letterSpacing: 0.15,
    textTransform: 'uppercase',
  },
  sub1: {
    fontSize: 20,
    lineHeight: 24,
    letterSpacing: 0.15,
  },
  sub2: {
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
  },
  sub3: {
    fontSize: 14,
    lineHeight: 24,
    letterSpacing: 0.1,
  },
  body1: {
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.5,
  },
  body2: {
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.24,
  },
  button: {
    fontSize: 14,
    letterSpacing: 1.25,
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.4,
  },
  overline: {
    fontSize: 10,
    lineHeight: 16,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
  },
});

interface TypographyProps extends TextProps {
  variant: TextVariant;
  color?: string;
  children: React.ReactNode;
}

const Typography = forwardRef<Text, TypographyProps>(
  ({ style = {}, variant, children, color, ...otherProps }: TypographyProps, ref) => {
    const { fonts, colors } = useTheme();
    const getFonts = useMemo(() => {
      switch (variant) {
        case 'h1':
        case 'h2':
          return fonts.headlineLarge;
        case 'h3':
        case 'h4':
          return fonts.headlineMedium;
        case 'h5':
        case 'h6':
          return fonts.titleLarge;
        case 'sub3':
          return fonts.bodyMedium;
        default:
          return fonts.default;
      }
    }, [variant, fonts.headlineLarge, fonts.headlineMedium, fonts.titleLarge, fonts.bodyMedium, fonts.default]);

    const getDefaultColor = useMemo(() => {
      switch (variant) {
        case 'button': {
          return colors.onPrimary;
        }
        default: {
          return colors.onSurface;
        }
      }
    }, [colors.onPrimary, colors.onSurface, variant]);
    return (
      <Text
        ref={ref}
        {...otherProps}
        style={[fontVariantStylesheet[variant], { ...getFonts }, { color: color ?? getDefaultColor }, style]}
      >
        {children}
      </Text>
    );
  },
);
export default Typography;
