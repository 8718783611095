import { getTimezoneOffset } from 'date-fns-tz';
import { getTimeZone } from 'react-native-localize';
import { AppsyncResult } from '../apis/appsyncHelper';
import { MarkerGJFeature } from '../components/map/content/MarkerCluster';
import { PodStatus, ReservationStatus } from '../types/appsync-types';
import { Pod } from '../types/misc.types';

export type GeoJsonWithReservation = {
  type: 'space';
  title: string;
  lon: number;
  lat: number;
  localRef: string;
  buildingRef: string;
  poiId: string;
  layerIndex: number;
  reservationStatus: ReservationStatus | null;
  podStatus: PodStatus;
  podTzOffsetMs: number;
  nextFreeStart: number; // Unix timestamp
  currentFreeEnd: number; // Unix timestamp
  selected: boolean;
};

export type GeoJsonWithVenueAvailability = {
  type: 'venue';
  available: number;
  total: number;
  podTzOffsetMs: number;
  nextFreeStart: number; // Unix timestamp
  currentFreeEnd: number; // Unix timestamp
  buildingRef: string;
  title: string;
};

export function convertGetBuildingResultToGeoJson(
  result: NonNullable<AppsyncResult<'getBuildingsWithinRadius'>['data']>,
  buildingNameTranslator: (title: string | null | undefined) => string,
): {
  venues: MarkerGJFeature<GeoJsonWithVenueAvailability>[];
  pois: MarkerGJFeature<GeoJsonWithReservation>[];
} {
  const res = {
    venues:
      result.getBuildingsWithinRadius
        ?.filter((b) => b && b.spaces && b.buildingRef && !Number.isNaN(b.lon) && !Number.isNaN(b.lat))
        ?.map((building, index) => {
          const spaces =
            building?.spaces?.filter(
              (s) =>
                s &&
                s.availabilityStatus?.status &&
                s.availabilityStatus.nextFreeStart &&
                s.availabilityStatus.nextFreeEnd,
            ) ?? [];
          const availableCount = spaces.reduce(
            (acc, s) => (s!.availabilityStatus!.status === PodStatus.FREE ? acc + 1 : acc),
            0,
          );
          let lastFreeEnd: number = 0;
          let firstFreeStart: number = 0;
          if (availableCount) {
            /* Find longest possible availability */
            lastFreeEnd = spaces.reduce(
              (lastFree, s) =>
                s!.availabilityStatus!.status === PodStatus.FREE
                  ? Math.max(lastFree, new Date(s!.availabilityStatus!.nextFreeEnd!).getTime() / 1000)
                  : lastFree,
              -1,
            );
          } else {
            /* Find first possible availability */
            firstFreeStart = spaces.reduce(
              (firstFree, s) => Math.min(firstFree, new Date(s!.availabilityStatus!.nextFreeStart!).getTime() / 1000),
              Infinity,
            );
          }
          const podToUtc = getTimezoneOffset(building?.timezone ?? getTimeZone(), new Date(firstFreeStart));
          const viewToUtc = getTimezoneOffset(getTimeZone(), new Date(firstFreeStart));
          const podTzOffsetMs = podToUtc - viewToUtc;
          const v: MarkerGJFeature<GeoJsonWithVenueAvailability> = {
            type: 'Feature',
            id: index + 1,
            properties: {
              type: 'venue',
              total: spaces.length ?? 0,
              available: availableCount,
              podTzOffsetMs,
              nextFreeStart: firstFreeStart,
              currentFreeEnd: lastFreeEnd,
              buildingRef: building!.buildingRef!,
              title: buildingNameTranslator(building!.title),
            },
            geometry: {
              type: 'Point',
              coordinates: [building!.lon!, building!.lat!],
            },
          };
          return v;
        })
        ?.filter((x) => !!x) ?? [],
    pois:
      result.getBuildingsWithinRadius
        /* Flatten to list of spaces */
        ?.reduce((accumulator, building) => {
          building?.spaces?.forEach((s) => (s ? accumulator.push(s) : null));
          return accumulator;
        }, [] as Pod[])
        .filter(
          (poi) =>
            poi &&
            poi.localRef &&
            poi.buildingRef &&
            !Number.isNaN(poi.lon) &&
            !Number.isNaN(poi.lat) &&
            poi.availabilityStatus?.status,
        )
        /* Convert to geojson */
        .map((poi, index) => {
          const isFree = poi.availabilityStatus?.status === PodStatus.FREE;
          const avStat = poi.availabilityStatus!;
          let currentFreeEnd = 0;
          let nextFreeStart = 0;
          if (isFree) {
            currentFreeEnd = new Date(avStat.nextFreeEnd!).getTime() / 1000;
          } else {
            nextFreeStart = new Date(avStat.nextFreeStart!).getTime() / 1000;
          }

          // We only take the first letter of the title as a workaround for this issue
          // https://github.com/biketti/biketti-pod-app/issues/287
          // TODO: Figure out better way to handle map short name vs full title
          const title = (poi?.title ?? 'S').charAt(0);
          const podToUtc = getTimezoneOffset(poi.building?.timezone ?? getTimeZone(), new Date(nextFreeStart));
          const viewToUtc = getTimezoneOffset(getTimeZone(), new Date(nextFreeStart));
          const podTzOffsetMs = podToUtc - viewToUtc;

          const v: MarkerGJFeature<GeoJsonWithReservation> = {
            type: 'Feature',
            id: index,
            properties: {
              type: 'space',
              poiId: poi?.id,
              title,
              localRef: poi!.localRef!,
              layerIndex: Number.parseInt(poi?.layerIndex ?? '0', 10),
              buildingRef: poi!.buildingRef!,
              lon: poi!.lon!,
              lat: poi!.lat!,
              currentFreeEnd,
              podTzOffsetMs,
              nextFreeStart,
              // The status will be yielded by the reservation subscription
              reservationStatus: null,
              podStatus: poi.availabilityStatus?.status!,
              // The selection is managed by MapView
              selected: false,
            },
            geometry: {
              type: 'Point',
              coordinates: [poi.lon!, poi.lat!],
            },
          };
          return v;
        }) ?? [],
  };
  return res;
}
