import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import Typography from './Typography';
import { UiDefaultIconName, UiIcon, UiIconType } from './SimpleListIcon';

type IconGenerator = (props: { size: number; color: string }) => React.ReactElement;
export type SummaryHeaderProps = {
  title: string;
  description?: string | JSX.Element;
  icon?: React.ReactElement | UiDefaultIconName | IconGenerator;
  iconFromSet?: UiIconType;
  iconOpacity?: number;
  topKeepout?: number;
  textColor?: string;
  titleAction?: {
    icon: UiDefaultIconName;
    accessibilityLabel: string;
    onPress: () => void;
  };
  titleContent?: JSX.Element;
  button?: {
    label: string;
    onPress: () => void;
    disabled?: boolean;
  };
} & ViewProps;

const MIN_ICON_SIZE = 162;

function SummaryIcon({
  opacity,
  iconFromSet,
  image,
  iconSource,
  ...other
}: {
  opacity: number | undefined;
  iconFromSet?: UiIconType;
  image?: ReactElement;
  iconSource?: IconGenerator;
}) {
  const { colors } = useTheme();
  const [iconSize, setIconSize] = useState<number>(162);
  const { iconStyle, iconColor } = useMemo(
    (): { iconStyle: ViewStyle; iconColor: string } => ({
      iconStyle: {
        position: 'absolute',
        /* height: SummaryHeaderIconSize, */
        right: 0,
        top: -20,
        minHeight: MIN_ICON_SIZE + 20,
        opacity: opacity ?? (iconSize > MIN_ICON_SIZE ? 0.2 : 0.1),
      },
      iconColor: iconSize > MIN_ICON_SIZE ? colors.onBackground : colors.inverseOnSurface,
    }),
    [opacity, iconSize, colors.onBackground, colors.inverseOnSurface],
  );
  const handleOnLayout = useCallback((evt: LayoutChangeEvent) => {
    setIconSize(Math.max(162, evt.nativeEvent.layout.width - 42));
  }, []);

  return (
    <View style={{ flex: 1, minHeight: 20 }} onLayout={handleOnLayout}>
      <View style={[iconStyle]}>
        {iconFromSet ? <UiIcon {...iconFromSet} size={iconSize} color={iconColor} /> : null}
        {image || null}
        {iconSource && typeof iconSource === 'function' ? iconSource({ size: iconSize, color: iconColor }) : null}
      </View>
    </View>
  );
}

export default function SummaryHeader({
  title,
  titleAction,
  titleContent,
  description,
  topKeepout,
  textColor,
  icon,
  iconFromSet,
  iconOpacity,
  button,
  style,
  ...other
}: SummaryHeaderProps) {
  const { colors } = useTheme();

  return (
    <View style={[{ width: '100%' }, style]} {...other}>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flexDirection: 'column', flexGrow: 1, maxWidth: 450, width: '100%' }}>
          {titleContent || null}
          {/* Title and description */}
          <View style={styles.textContainer}>
            {topKeepout ? <View style={[{ minHeight: topKeepout }, styles.textTopBuffer]} /> : null}
            <Typography
              variant="h5"
              color={textColor ?? colors.onBackground}
              style={styles.title}
              onPress={titleAction?.onPress}
            >
              {title}{' '}
              {titleAction?.icon ? (
                <UiIcon style={{ marginLeft: 4 }} name={titleAction.icon} size={16} color={colors.inverseOnSurface} />
              ) : (
                ''
              )}
            </Typography>
            {typeof description === 'string' ? (
              <Typography variant="body2" color={textColor ?? colors.onBackground}>
                {description}
              </Typography>
            ) : (
              description
            )}
            {topKeepout ? <View style={styles.textBottomBuffer} /> : null}
          </View>
          {/* Optional action button */}
          {button ? (
            <View style={styles.buttonWrapper}>
              <Button
                mode={'outlined'}
                compact
                labelStyle={styles.buttonLabel}
                onPress={button.onPress}
                disabled={button.disabled}
                textColor={colors.inverseOnSurface}
              >
                {button.label}
              </Button>
            </View>
          ) : null}
        </View>
        {/* Icon */}
        {iconFromSet ? <SummaryIcon iconFromSet={iconFromSet} opacity={iconOpacity} /> : null}
        {icon && typeof icon === 'string' ? <SummaryIcon iconFromSet={{ name: icon }} opacity={iconOpacity} /> : null}
        {icon && typeof icon === 'function' ? <SummaryIcon iconSource={icon} opacity={iconOpacity} /> : null}
        {icon && typeof icon !== 'function' && typeof icon !== 'string' ? (
          <SummaryIcon image={icon} opacity={iconOpacity} />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  textTopBuffer: {
    flexGrow: 1,
  },
  textBottomBuffer: {
    minHeight: 8 /* margin */,
    flexGrow: 1,
  },
  textContainer: {
    minHeight: 172,
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 450,
    width: '80%',
  },
  title: { paddingBottom: 8 },
  buttonWrapper: {
    // Need to wrap button in a Row for auto width
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    maxWidth: 450,
  },
  buttonLabel: { paddingHorizontal: 30 },
});
