import { MapboxGeoJSONFeature } from 'maplibre-gl';
import type maplibregl from 'maplibre-gl';

// TODO: Shouldn't really have FloorInfo type defined in the ui
import { FloorInfo } from '../../../ui/FloorSwitcher';

export type HasBuildingChangedResult = {
  buildingInView: 'same' | 'new' | 'none';
  newBuilding?: {
    title: string;
    floors: FloorInfo[];
    buildingRef: string;
    feature: maplibregl.MapboxGeoJSONFeature;
  };
};

export type CenterBuildingMonitorVars = {
  onCenterBuildingChanged: (res: HasBuildingChangedResult) => void;
  minimumZoom: number;
};

const RegisterCenterBuildingMonitor = (
  vars: CenterBuildingMonitorVars,
  basicMap: maplibregl.Map,
  lib: typeof maplibregl,
  window: Window,
): void => {
  const map = basicMap as maplibregl.Map & {
    biketti: {
      centerBuildingMonitor: { reportedBuilding: string | undefined };
    };
  };
  /* Initialise monitoring variables */
  if (!map.biketti) {
    map.biketti = {
      centerBuildingMonitor: {
        reportedBuilding: undefined,
      },
    };
  }
  if (!map.biketti.centerBuildingMonitor) map.biketti.centerBuildingMonitor = { reportedBuilding: undefined };

  const genFloorInfo = (f: MapboxGeoJSONFeature): FloorInfo[] => {
    const floors: FloorInfo[] = [];
    const names: string[] = f.properties?.floorNameList.split(',');
    const indexStrings: string[] =
      typeof f.properties?.floorIndexList === 'number'
        ? [f.properties.floorIndexList.toString()]
        : f.properties?.floorIndexList.split(',');

    for (let i = 0; i < names.length; i++) {
      floors.unshift({
        name: names[i],
        index: parseInt(indexStrings[i], 10),
      });
    }
    return floors;
  };

  /* This function checks queryRenderedFeature results */
  const checkFeats = (
    feats: maplibregl.MapboxGeoJSONFeature[],
    prevBuilding: string | undefined,
  ): HasBuildingChangedResult | undefined => {
    /* Part a: Check if current building is still the one in the center view */
    for (let i = 0; i < feats.length; i++) {
      if (feats[i].properties?.buildingRef === prevBuilding) {
        // All good, still same building
        return { buildingInView: 'same' };
      }
    }

    /* Part b: Check if some other building is present */
    for (let i = 0; i < feats.length; i++) {
      if (feats[i].properties?.buildingRef) {
        // Some new building it seems, time to report
        const _props = feats[i].properties!;
        return {
          buildingInView: 'new',
          newBuilding: {
            title: feats[i].properties?.title,
            buildingRef: feats[i].properties?.buildingRef,
            floors: genFloorInfo(feats[i]),
            feature: feats[i],
          },
        };
      }
    }
  };

  const reportBuilding = (res: HasBuildingChangedResult) => {
    vars.onCenterBuildingChanged(res);
    map.biketti.centerBuildingMonitor.reportedBuilding = res.newBuilding?.buildingRef;
  };

  const runMonitoring = (awaitIdle: boolean) => {
    // This could be optimized so that there is no need for this check every moveend if we know a biketti style is loaded.
    if (!map.getLayer('building_outline')) return;

    const prevBuilding = map.biketti.centerBuildingMonitor.reportedBuilding;
    if (map.getZoom() < vars.minimumZoom) {
      if (prevBuilding !== undefined) {
        reportBuilding({ buildingInView: 'none' });
      }
      return;
    }

    const w = map.getCanvas().width / window.devicePixelRatio;
    const h = map.getCanvas().height / window.devicePixelRatio;
    const padding = map.getPadding();
    const offX = padding.left - padding.right;
    const offY = padding.top - padding.bottom;
    const centerRadius: number = 5;
    const cx: number = (w + offX) / 2;
    const cy: number = (h + offY) / 2;
    const centerBox: [[number, number], [number, number]] = [
      [cx - centerRadius, cy - centerRadius],
      [cx + centerRadius, cy + centerRadius],
    ];

    /* Phase 1: Check center point */
    const cFeats = map.queryRenderedFeatures(centerBox, {
      layers: ['building_outline'],
    });
    const centerResult = checkFeats(cFeats, prevBuilding);
    if (centerResult) {
      if (centerResult.buildingInView !== 'same') {
        // console.log("Center based building switched.");
        reportBuilding(centerResult);
      }
      return;
    }

    /* Phase 2: Check whole screen */
    const wholeScreenBox: [[number, number], [number, number]] = [
      [0, 0],
      [w, h],
    ];
    const wFeats = map.queryRenderedFeatures(wholeScreenBox, {
      layers: ['building_outline'],
    });
    const wholeScreenResult = checkFeats(wFeats, prevBuilding);
    if (wholeScreenResult) {
      if (wholeScreenResult.buildingInView !== 'same') {
        // console.log("Whole screen based building switched.");
        reportBuilding(wholeScreenResult);
      }
      return;
    }

    if (prevBuilding !== undefined) {
      if (awaitIdle) {
        map.once('idle', () => runMonitoring(false));
      } else {
        reportBuilding({ buildingInView: 'none' });
      }
    }
  };

  map.on('idle', () => runMonitoring(false));
  map.on('zoomend', () => runMonitoring(false));
  // Trigger once to check what we are looking at now, even before map is moved.
  runMonitoring(true);
};

export default RegisterCenterBuildingMonitor;
