import { configureFonts, MD3LightTheme, MD3Theme, useTheme } from 'react-native-paper';
import { loadAsync, useFonts } from 'expo-font';
import { LinearGradientProps } from 'expo-linear-gradient';

const customFonts = {
  // fonts used by react-navigation and material top navigation.
  // initialise them here so that they're loaded before navigation tabs are added
  // 'material-icons': require('../../assets/fonts/MaterialIcons-Regular.otf'),
  'material-community': require('../../assets/fonts/MaterialCommunityIcons.otf'),
  'simple-line-icons': require('../../assets/fonts/SimpleLineIcons.otf'),
  material: require('../../assets/fonts/MaterialIcons-Regular.otf'),
  // fonts used by the app. TODO consider renaming same way as above in the typography.ts file as well
  Roboto: require('../../assets/fonts/Roboto-Light.otf'),
  'Roboto-Regular': require('../../assets/fonts/Roboto-Regular.otf'),
  'RobotoCondensed-Regular': require('../../assets/fonts/RobotoCondensed-Regular.otf'),
  'Roboto-Medium': require('../../assets/fonts/Roboto-Medium.otf'),
  'OpenSans-Bold': require('../../assets/fonts/OpenSans-Bold.ttf'),
  'OpenSans-SemiBold': require('../../assets/fonts/OpenSans-SemiBold.ttf'),
  'OpenSans-Light': require('../../assets/fonts/OpenSans-Light.ttf'),
  'OpenSans-Regular': require('../../assets/fonts/OpenSans-Regular.ttf'),
  'OpenSans-Medium': require('../../assets/fonts/OpenSans-Medium.ttf'),
  'Ubuntu-Bold': require('../../assets/fonts/Ubuntu-Bold.ttf'),
  'Ubuntu-Light': require('../../assets/fonts/Ubuntu-Light.ttf'),
  'Ubuntu-Regular': require('../../assets/fonts/Ubuntu-Regular.ttf'),
  'Ubuntu-Medium': require('../../assets/fonts/Ubuntu-Medium.ttf'),
};

/* 
Please avoid adding more unless you absolutely need to. Much 
better is to use standard theme colors, and overriding them
if needed.
*/
type CustomBicyclehutTheme = {
  gradientLayer1: LinearGradientProps;
  gradientLayer2: LinearGradientProps;
  scrollViewFadeGradient: LinearGradientProps;
};

export type BicyclehutTheme = MD3Theme & { custom: CustomBicyclehutTheme };
export const useBicyclehutTheme = () => useTheme<BicyclehutTheme>();

export const useLoadAsyncForFonts = async () => {
  await loadAsync(customFonts);
};

export function useCustomFonts() {
  return useFonts(customFonts);
}

export function getPaperTheme() {
  const fontDefaults = configureFonts({
    config: {
      fontFamily: 'OpenSans-Regular',
      fontWeight: 'normal',
    },
    isV3: true,
  });
  return {
    ...MD3LightTheme, // or MD3DarkTheme
    roundness: 4,
    colors: {
      primary: '#e6007e',
      onPrimary: '#ffffff',
      primaryContainer: '#ffd8e6',
      onPrimaryContainer: '#3d0024',
      secondary: '#f98fce',
      onSecondary: '#000000',
      secondaryContainer: '#ffd9e2',
      onSecondaryContainer: '#3e001e',
      tertiary: '#b40b74',
      onTertiary: '#ffffff',
      tertiaryContainer: '#ffd8e6',
      onTertiaryContainer: '#3d0024',
      error: '#ba1a1a',
      onError: '#ffffff',
      errorContainer: '#ffdad6',
      onErrorContainer: '#410002',
      background: '#fafdfd',
      onBackground: '#1a1a1a',
      surface: '#fafdfd',
      onSurface: '#1a1a1a',
      surfaceVariant: '#dbe4e6',
      onSurfaceVariant: '#3f484a',
      outline: '#e6007e',
      outlineVariant: '#bfc8ca',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#333333',
      inverseOnSurface: '#ffffff',
      inversePrimary: '#ffafd1',
      elevation: {
        level0: 'transparent',
        level1: '#eef6f6',
        level2: '#ecf4f4',
        level3: '#eaf2f2',
        level4: '#e8f0f0',
        level5: '#e6eeee',
      },
      surfaceDisabled: '#191c1d1f',
      onSurfaceDisabled: '#191c1d61',
      backdrop: '#29323466',
    },
    custom: {
      gradientLayer1: {
        /* Base gradient */
        colors: ['#e6007e', '#e6007e'],
        start: { x: 0.25, y: 0 },
        end: { x: 0.8, y: 1 },

        locations: [0, 1],
      },
      gradientLayer2: {
        /* A darker shade at the bottom of the element to give slight-3d effect */
        colors: ['rgba(0, 0, 0, 0.33)', 'rgba(0, 0, 0, 0)'],
        start: { x: 0.5, y: 1 },
        end: { x: 0.5, y: 0 },
        locations: [0, 1],
      },
      scrollViewFadeGradient: {
        colors: ['rgba(31, 48, 63, 0)', 'rgba(31, 48, 63, 1)'],
      },
    },

    // TODO: are these correct?
    fonts: {
      ...fontDefaults,
      labelMedium: {
        ...fontDefaults.labelMedium,
        fontSize: 16,
      },
      bodySmall: {
        ...fontDefaults.bodySmall,
        fontSize: 14,
      },
      headlineSmall: {
        ...fontDefaults.headlineSmall,
        fontSize: 17,
        fontFamily: 'Ubuntu-Regular',
        fontWeight: '700',
        fontStyle: 'italic',
      },
      headlineMedium: {
        ...fontDefaults.headlineMedium,
        fontFamily: 'Ubuntu-Regular',
        fontWeight: '700',
        fontStyle: 'italic',
      },
      headlineLarge: {
        ...fontDefaults.headlineLarge,
        fontFamily: 'Ubuntu-Regular',
        fontWeight: '700',
        fontStyle: 'italic',
      },
      titleLarge: {
        ...fontDefaults.default,
        fontSize: 24,
        fontFamily: 'Ubuntu-Regular',
        fontWeight: '700',
        fontStyle: 'italic',
      },
    },
  };
}
