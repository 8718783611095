/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react';
import { Platform, View, TouchableOpacity } from 'react-native';
import { ActivityIndicator, Button } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import color from 'color';
import Typography from './Typography';
import { UiDefaultIconName, UiIcon, UiIconType } from './SimpleListIcon';
import { useBicyclehutTheme } from '../utils/paperTheme';

type ButtonProps = React.ComponentProps<typeof Button>;
export type GradientButtonProps = Omit<ButtonProps, 'icon'> & {
  loading?: boolean;
  rounded?: boolean;
  narrow?: boolean;
  icon?: UiIconType | UiDefaultIconName;
};

const GradientButton = ({
  children,
  loading,
  disabled,
  style,
  rounded,
  icon,
  narrow,
  ...otherProps
}: PropsWithChildren<GradientButtonProps>) => {
  const { roundness, dark, custom, colors } = useBicyclehutTheme();
  const disabledBackgroundColor = color(dark ? '#ffffff' : '#000000')
    .alpha(0.12)
    .rgb()
    .string();
  const renderButton = () => {
    return (
      <TouchableOpacity
        disabled={disabled || loading}
        style={{
          width: '100%',
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          marginBottom: 0,
          backgroundColor: disabled || loading ? disabledBackgroundColor : '#ffffff00',
          padding: 6,
          display: 'flex',
          alignItems: 'center',
          borderRadius: rounded ? 24 : roundness,
          justifyContent: 'center',
          height: 47,
        }}
        onPress={otherProps.onPress}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: Platform.OS !== 'web' ? 4 : 0,
            paddingHorizontal: 10,
          }}
        >
          {/* Loading indicator, if loading */}
          {loading ? <ActivityIndicator size={16} style={{ marginRight: 10 }} color={colors.secondary} /> : null}

          {/* Button icon, if specified */}
          {icon && typeof icon === 'string' ? (
            <UiIcon name={icon} size={24} color={colors.secondary} style={{ marginRight: 10 }} />
          ) : null}
          {icon && typeof icon !== 'string' ? (
            <UiIcon {...icon} size={24} color={colors.secondary} style={{ marginRight: 10 }} />
          ) : null}

          {/* Actual button content. */}
          <Typography variant={'button'}>{children}</Typography>
        </View>
      </TouchableOpacity>
    );
  };

  const renderGradientButton = () => {
    return (
      <LinearGradient {...custom.gradientLayer1} style={{ borderRadius: rounded ? 24 : roundness }}>
        <LinearGradient {...custom.gradientLayer2}>{renderButton()}</LinearGradient>
      </LinearGradient>
    );
  };
  return (
    <View
      style={[
        {
          flexDirection: narrow ? 'row' : 'column',
          justifyContent: 'center',
        },
        style,
      ]}
    >
      {loading || disabled ? renderButton() : renderGradientButton()}
    </View>
  );
};
export default GradientButton;
