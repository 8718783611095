import React, { FC, useEffect } from 'react';
import maplibregl from 'maplibre-gl';

import { JsonValue } from '../wrap/WrappedWebView';
import RegisterCenterBuildingMonitor, {
  CenterBuildingMonitorVars,
  HasBuildingChangedResult,
} from './helpers/CenterBuildingMonitor';
import { MapWithMovementControls, MoveCompletion, MoveToOptions } from './helpers/MovementHandling';
import SwitchFloors from './helpers/FloorSwitching';
import { MarkerClusterProps } from './MarkerCluster';
import { ClickAndEventMonitoringMap, EnrichedMapEvent } from './helpers/ClickMonitor';
import { useMapWrapper } from '../MapContext';
import { BluedotProps } from './Bluedot';

/**
 * Idea of the base map is that it implements some basic features of our indoor map, such
 * as monitoring for the indoor building, switching to a desired floor etc. However it
 * does not make these decisions of switching floors etc. It is dumb and only does as it
 * is told.
 *
 * No react components are in this view.
 */
export type BaseMapChild =
  | React.ReactElement<MarkerClusterProps<any, any, any>>
  | React.ReactElement<BluedotProps>
  | undefined;
export type BaseMapChildren = BaseMapChild | BaseMapChild[] | undefined;

export type BaseMapProps = {
  mapStyle?: maplibregl.Style;
  suggestedOrientation?: maplibregl.CameraOptions;
  moveTarget?: MoveToOptions;
  contentPadding?: maplibregl.PaddingOptions;
  onUserInteraction?: () => any;
  onMoveCompletion?: MoveCompletion;
  onClick?: (e: EnrichedMapEvent) => void;
  onCenterBuildingChanged?: (r: HasBuildingChangedResult) => void;

  children?: void;
};

const MINIMUM_FLOORSWITCHER_ZOOM = 14;

export const BaseMap: FC<BaseMapProps> = (props) => {
  const { mapWrap, isStyleLoaded, selectedBuildingAndFloor } = useMapWrapper();
  useEffect(() => {
    if (isStyleLoaded) {
      if (props.onCenterBuildingChanged) {
        mapWrap.run<CenterBuildingMonitorVars>(RegisterCenterBuildingMonitor, {
          onCenterBuildingChanged: props.onCenterBuildingChanged,
          minimumZoom: MINIMUM_FLOORSWITCHER_ZOOM,
        });
      } else {
        // TODO, remove or change?
      }
    }
  }, [props.onCenterBuildingChanged, mapWrap, isStyleLoaded]);

  /* Click handler */
  useEffect(() => {
    if (isStyleLoaded) {
      mapWrap.run(
        (vars, basicMap, lib, win) => {
          const map = basicMap as ClickAndEventMonitoringMap;
          map.biketti.removeClickHandler('base-map-click-handler');
          if (vars.onClick) {
            map.biketti.addClickHandler('base-map-click-handler', vars.onClick);
          }
        },
        { onClick: props.onClick || null },
      );

      /* Cleanup */
      return () => {
        mapWrap.run((vars, basicMap, lib, win) => {
          const map = basicMap as ClickAndEventMonitoringMap;
          map.biketti.removeClickHandler('base-map-click-handler');
        });
      };
    }
  }, [props.onClick, mapWrap, isStyleLoaded]);

  /* User interaction handler */
  useEffect(() => {
    if (isStyleLoaded) {
      mapWrap.run(
        (vars, basicMap, lib, win) => {
          const map = basicMap as ClickAndEventMonitoringMap;
          map.biketti.removeEventHandler('base-map-touch-handler', 'touchstart');
          map.biketti.removeEventHandler('base-map-touch-handler', 'mousedown');
          map.biketti.removeEventHandler('base-map-touch-handler', 'wheel');
          if (vars.onTouchStart) {
            map.biketti.addEventHandler('base-map-touch-handler', 'touchstart', vars.onTouchStart);
            map.biketti.addEventHandler('base-map-touch-handler', 'mousedown', vars.onTouchStart);
            map.biketti.addEventHandler('base-map-touch-handler', 'wheel', vars.onTouchStart);
          }
        },
        { onTouchStart: props.onUserInteraction || null },
      );

      /* Cleanup */
      return () => {
        mapWrap.run((vars, basicMap, lib, win) => {
          const map = basicMap as ClickAndEventMonitoringMap;
          map.biketti.removeEventHandler('base-map-touch-handler', 'touchstart');
          map.biketti.removeEventHandler('base-map-touch-handler', 'mousedown');
          map.biketti.removeEventHandler('base-map-touch-handler', 'wheel');
        });
      };
    }
  }, [props.onUserInteraction, mapWrap, isStyleLoaded]);

  /* Content padding */
  useEffect(() => {
    if (isStyleLoaded) {
      mapWrap.run<{ padding: { top: number; bottom: number; left: number; right: number } }>(
        (vars, basicMap, win) => {
          const map = basicMap as MapWithMovementControls;
          map.biketti.moveTo(
            {
              allowInterrupting: false,
              replaceId: 'padding',
              movementType: 'ease',
              options: {
                padding: vars.padding,
              },
            },
            null,
          );
        },
        {
          padding: props.contentPadding ?? {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
      );
    }
  }, [props.contentPadding, mapWrap, isStyleLoaded]);

  /* Move map */
  useEffect(() => {
    if (props.moveTarget) {
      // console.log('Move', props.moveTarget.movementType);
      mapWrap.run<{ target: JsonValue; completion: MoveCompletion | null }>(
        (vars, basicMap, win) => {
          const map = basicMap as MapWithMovementControls;
          const moveOpts = vars.target as unknown as MoveToOptions;
          const completion = vars.completion || (() => {});
          map.biketti.moveTo(moveOpts, completion);
        },
        {
          target: props.moveTarget as unknown as JsonValue,
          completion: props.onMoveCompletion ?? null,
        },
      );
    }
  }, [props.moveTarget, props.onMoveCompletion, mapWrap]);

  /* Switch floors */
  useEffect(() => {
    if (selectedBuildingAndFloor && isStyleLoaded) {
      mapWrap.run(SwitchFloors, {
        buildingRef: selectedBuildingAndFloor.building,
        layerIndex: selectedBuildingAndFloor.floor,
      });
    }
  }, [selectedBuildingAndFloor, mapWrap, isStyleLoaded]);

  return null;
};
