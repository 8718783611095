import React, { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import type maplibregl from 'maplibre-gl';
import type { Style } from 'maplibre-gl';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator, useTheme } from 'react-native-paper';
import { WebViewNavigationEvent, WebViewErrorEvent } from 'react-native-webview/lib/WebViewTypes';
import MapLibreWrapper from './wrap/MapLibreWrapper';
import RegisterClickMonitor from './content/helpers/ClickMonitor';
import { initMovementHandling } from './content/helpers/MovementHandling';
import { JsonValue } from './wrap/WrappedWebView';
import { enclosingCircle } from './content/helpers/enclosingCircle';
import { getStyle } from './content/helpers/style/MapStyler';
import podExports from '../../../pod-exports.json';

type MapContextValues = {
  mapWrap: MapLibreWrapper;
  isStyleLoaded: boolean;
  selectedBuildingAndFloor?: { building: string; floor: number };
  changeStyle: (s: Style) => void;
};
const MapContext = React.createContext<MapContextValues | undefined>(undefined);

export function useMapWrapper() {
  const value = useContext(MapContext);
  if (!value) {
    throw new Error('Malformed useMapWrapper!');
  }
  return value;
}
enum MapLoadingStatus {
  NONE,
  STYLE_LOADING,
  READY,
}

export function MapProvider({
  selectedBuildingAndFloor,
  onStoppedCamera,
  children,
}: {
  onStoppedCamera?: (c: maplibregl.CameraOptions) => void;
  selectedBuildingAndFloor?: { building: string; floor: number };
  children: ReactNode;
}) {
  const wrapperRef = useRef<MapLibreWrapper>(null);
  const [loadingStatus, setLoadingStatus] = useState<MapLoadingStatus>(MapLoadingStatus.NONE);
  const { colors } = useTheme();

  const changeStyle = useCallback(
    (newStyle: Style, initialLocation?: { lat: number; lng: number }, initialZoom?: number) => {
      setLoadingStatus(MapLoadingStatus.STYLE_LOADING);
      wrapperRef.current!.run(
        (vars, map, _lib, _win) => {
          map.once('style.load' as any, () => {
            if (map.isStyleLoaded()) {
              vars.onStyleLoaded();
            } else {
              map.once('idle', () => {
                vars.onStyleLoaded();
              });
            }
          });

          map.on('error' as any, (event) => {
            console.log('map error', event);
          });

          map.setStyle(vars.style as unknown as Style, {
            diff: false,
          });
          if (vars.initialZoom !== null) map.setZoom(vars.initialZoom);
          if (vars.initialLocation) map.setCenter(vars.initialLocation);
        },
        {
          style: newStyle as unknown as JsonValue,
          initialZoom: initialZoom ?? null,
          initialLocation: initialLocation ?? null,
          onStyleLoaded: () => setLoadingStatus(MapLoadingStatus.READY),
        },
      );
    },
    [],
  );

  const handleMapCreated = useCallback(
    (e: WebViewNavigationEvent | WebViewErrorEvent) => {
      /* Initialize Sentry */
      if (!__DEV__) {
        wrapperRef.current!.run(
          (vars, basicMap, lib, win) => {
            (vars.SentryLib as any).init({ dsn: vars.sentryDSN });
          },
          {
            sentryDSN: podExports.sentryDsn,
            SentryLib: { _execution_context_variable: 'Sentry' },
          },
        );
      }
      /* Initialize map */
      // TODO: Create map instance

      /* Create methods for adding and removing click handlers */
      wrapperRef.current!.run(RegisterClickMonitor);

      /* Adding the special moveTo api we always use instead of flyTo etc */
      /* Add camera watching callback */
      wrapperRef.current!.run(initMovementHandling, {
        enclosingCircleJS: `(${enclosingCircle.toString()})()`,
        onStoppedCamera: onStoppedCamera ?? null,
        warn: (...msg: any[]) => console.warn(...msg),
      });

      /* Load style */
      changeStyle(getStyle('light'), { lng: 24.71457725014443, lat: 62.061770062905566 }, 3.8);
    },
    [onStoppedCamera, changeStyle],
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        mapWrap: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          opacity: loadingStatus !== MapLoadingStatus.NONE ? 1 : 0,
        },
        loadingContainer: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: 'center',
          justifyContent: 'center',
        },
      }),
    [loadingStatus],
  );

  const values: MapContextValues = useMemo(() => {
    return {
      mapWrap: wrapperRef.current!,
      isStyleLoaded: loadingStatus === MapLoadingStatus.READY,
      selectedBuildingAndFloor,
      changeStyle,
    };
  }, [loadingStatus, selectedBuildingAndFloor, changeStyle]);
  return (
    <>
      <MapLibreWrapper ref={wrapperRef} onLoadEnd={handleMapCreated} style={styles.mapWrap} />
      {loadingStatus !== MapLoadingStatus.READY ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator style={{ paddingBottom: 50 }} animating={true} color={colors.secondary} />
        </View>
      ) : null}

      <MapContext.Provider value={values}>
        {wrapperRef.current && loadingStatus !== MapLoadingStatus.NONE ? children : null}
      </MapContext.Provider>
    </>
  );
}
