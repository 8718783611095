// @refresh reset
// Resets the state of functional component. See https://reactnative.dev/docs/fast-refresh

import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { SupportedTranslation, useI18n } from '../../context/I18nContext';
import useScreenSize from '../../hooks/useScreenSize';
import { getData, InternalStorageItemKey } from '../../utils/internalStorage';
import Container from '../Container';
import TextModal from './TextModal';
import Typography from '../Typography';
import { useBicyclehutTheme } from '../../utils/paperTheme';

// This is completely independent of the app versioning.
// Let's increase this number each time we want to show a new update modal.
export const whatsNewVersion = 3;

// Update these everytime you wan't to show user new updates
const updateListItems: Record<SupportedTranslation, string[]> = {
  [SupportedTranslation.en]: [],
  [SupportedTranslation.enSE]: [],
  [SupportedTranslation.fi]: [],
  [SupportedTranslation.sv]: [],
  [SupportedTranslation.svSE]: [],
};

/**
 * Don't show update modal:
 * 1.) if we don't have update strings for user's selected locale
 * 2.) Update wizard is already shown
 */
export async function shouldShowUpdateModal(locale: SupportedTranslation) {
  if (!updateListItems[locale] || updateListItems[locale].length === 0) return false;
  const shownWhatsNewVersion = await getData<number>(InternalStorageItemKey.WHATS_NEW_VERSION);
  if (!shownWhatsNewVersion || shownWhatsNewVersion < whatsNewVersion) {
    return true;
  }
  return false;
}

export default function UpdateModal({ locale, onDismiss }: { locale: SupportedTranslation; onDismiss: () => void }) {
  const { I18n } = useI18n();
  const {
    custom: { scrollViewFadeGradient },
    colors,
  } = useBicyclehutTheme();
  const { windowHeight } = useScreenSize();

  return (
    <TextModal
      title={I18n.t('mapview.updateModal.title')}
      description={I18n.t('mapview.updateModal.description')}
      icon={'rocket'}
      buttons={[
        {
          title: I18n.t('general.ok'),
          onPress: onDismiss,
        },
      ]}
    >
      <Container style={{ maxHeight: windowHeight * 0.4 }}>
        <ScrollView scrollEnabled contentContainerStyle={{ paddingBottom: 50 }} style={{ flexGrow: 0 }}>
          {updateListItems[locale].map((value, index) => {
            const key = `${index}-${value}`;
            return (
              <View key={key} style={styles.listItem}>
                <View style={{ marginEnd: 10 }}>
                  <Text style={[styles.bullet, { color: colors.onSurface }]}>{'\u2B24'}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Typography variant={'body2'}>{value}</Typography>
                </View>
              </View>
            );
          })}
        </ScrollView>
        <LinearGradient {...scrollViewFadeGradient} style={styles.gradient} />
      </Container>
    </TextModal>
  );
}

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    marginVertical: 4,
    paddingStart: 20,
    paddingEnd: 10,
  },
  bullet: {
    fontSize: 6,
    lineHeight: 20,
  },
  gradient: {
    height: 60,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
});
