import type maplibregl from 'maplibre-gl';
// import { CircleLayer } from "maplibre-gl";

export type BluedotStyle = {
  pulseAnimationDuration: number;
  dotFillColor: string;
  dotStrokeColor: string;
  dotStrokeWidth: number;
  dotRadius: number;
  haloRGB: [number, number, number];
  haloRadius: number;
  bearingRadius: number;
};

export type BluedotLocation = {
  building: string | null;
  floor: number | null;
  lat: number;
  lng: number;
};
export type BluedotAccuracy = {
  lat: number;
  accuracyM: number | null;
};

export type BluedotBearingMode = 'hidden' | 'rotate_icon' | 'rotate_map';

export type BluedotBearing = {
  valueDeg: number;
  accuracyDeg: number;
};

export type BluedotMap = maplibregl.Map & {
  biketti: {
    bluedot_animation_paused?: boolean;
    bluedot_icon_bearing?: number;
    bluedot_icon_bearing_accuracy?: number;
    bluedot_bearing_mode?: BluedotBearingMode;
    bluedot_location_lat?: number;
    bluedot_location_lon?: number;

    center_offset: [number, number];
  };
};

export type SetBluedotBearingVars = {
  bearingMode: BluedotBearingMode;
  bearing: BluedotBearing | null;
};

export function setBluedotBearing(
  vars: SetBluedotBearingVars,
  basicMap: maplibregl.Map,
  lib: typeof maplibregl,
  win: Window,
) {
  const map = basicMap as BluedotMap;
  if (!map.biketti) map.biketti = { center_offset: [0, 0] };

  if (vars.bearingMode === 'rotate_icon' && vars.bearing) {
    map.biketti.bluedot_bearing_mode = vars.bearingMode;
    map.biketti.bluedot_icon_bearing = vars.bearing.valueDeg;
    map.biketti.bluedot_icon_bearing_accuracy = vars.bearing.accuracyDeg;
  } else if (vars.bearingMode === 'rotate_map' && vars.bearing) {
    map.biketti.bluedot_bearing_mode = vars.bearingMode;
    map.biketti.bluedot_icon_bearing = vars.bearing.valueDeg;
    map.biketti.bluedot_icon_bearing_accuracy = vars.bearing.accuracyDeg;

    const rotationOffsetPx: [number, number] = [map.biketti.center_offset[0], map.biketti.center_offset[1]];
    if (!isNaN(map.biketti.bluedot_location_lat as number) && !isNaN(map.biketti.bluedot_location_lon as number)) {
      const bluedotPoint = map.project([
        map.biketti.bluedot_location_lon as number,
        map.biketti.bluedot_location_lat as number,
      ]);
      rotationOffsetPx[0] = bluedotPoint.x - map.biketti.center_offset[0];
      rotationOffsetPx[1] = bluedotPoint.y - map.biketti.center_offset[1];
    }
    map.rotateTo(vars.bearing.valueDeg, {
      animate: true,
      offset: rotationOffsetPx,
      duration: 100,
    });
  } else {
    map.biketti.bluedot_bearing_mode = 'hidden';
  }
}

export function setBluedotAccuracy(vars: BluedotAccuracy, map: maplibregl.Map, lib: typeof maplibregl, win: Window) {
  /* Update accuracy circle */
  if (vars.accuracyM !== null) {
    const pixelRadiusOnZoom24 =
      vars.accuracyM / ((156543.03392 * Math.cos((vars.lat * Math.PI) / 180)) / Math.pow(2, 24));

    map.setFeatureState({ id: 123456, source: 'bluedot' }, { pixelRadiusZ24: pixelRadiusOnZoom24 });
  } else {
    map.setFeatureState({ id: 123456, source: 'bluedot' }, { pixelRadiusZ24: 0 });
  }
}

export function setBluedotAnimating(
  isAnimating: boolean,
  basicMap: maplibregl.Map,
  lib: typeof maplibregl,
  win: Window,
) {
  const map = basicMap as BluedotMap;
  map.biketti.bluedot_animation_paused = !isAnimating;
  if (isAnimating) {
    // start paints
    map.triggerRepaint();
  }
}
export function setBluedotLocation(
  vars: BluedotLocation | null,
  basicMap: maplibregl.Map,
  lib: typeof maplibregl,
  win: Window,
) {
  const map = basicMap as BluedotMap;
  if (!map.biketti) map.biketti = { center_offset: [0, 0] };
  const src = map.getSource('bluedot') as maplibregl.GeoJSONSource;

  if (!src) {
    return;
  }

  if (!vars || vars.lat === undefined) {
    src.setData({ type: 'FeatureCollection', features: [] });
    return;
  }
  src.setData({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        id: 123456,
        properties: {
          buildingRef: vars.building,
          layerIndex: vars.floor,
        },
        geometry: {
          type: 'Point',
          coordinates: [vars.lng, vars.lat],
        },
      },
    ],
  });
}

export function initBluedot(vars: BluedotStyle, basicMap: maplibregl.Map, lib: typeof maplibregl, window: Window) {
  const map = basicMap as BluedotMap;
  if (!map.biketti) map.biketti = { center_offset: [0, 0] };

  const haloColorStart = `rgba(${vars.haloRGB[0]}, ${vars.haloRGB[1]}, ${vars.haloRGB[2]},`;
  const cx = vars.haloRadius;
  const cy = vars.haloRadius;
  const size = vars.haloRadius * 2;

  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8ClampedArray(size * size * 4),
    context: null as unknown as CanvasRenderingContext2D,

    // get rendering context for the map canvas when layer is added to the map
    onAdd() {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
    },

    // called once before every frame where the icon will be used
    render(gl: WebGLRenderingContext, matrix: Array<number>) {
      if (map.biketti.bluedot_animation_paused) return;
      const t = (performance.now() % vars.pulseAnimationDuration) / vars.pulseAnimationDuration;

      const outerRadius = (vars.haloRadius - vars.dotRadius) * t * 1.25 + vars.dotRadius;
      const { context } = this;

      // draw pulsing circle
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(cx, cy, outerRadius, 0, Math.PI * 2);
      context.fillStyle = haloColorStart + Math.max(0, 0.75 - t) + ')';
      context.fill();

      // draw bearing
      if (map.biketti.bluedot_bearing_mode !== 'hidden' && map.biketti.bluedot_icon_bearing) {
        context.save();
        context.translate(cx, cy);
        context.rotate((map.biketti.bluedot_icon_bearing * Math.PI) / 180.0);

        // draw bearing accuracy
        if (map.biketti.bluedot_icon_bearing_accuracy) {
          context.beginPath();
          const d = (map.biketti.bluedot_icon_bearing_accuracy * Math.PI) / 180;
          const halfPI = Math.PI / 2; // zero degrees is to the right, on a map it is up
          context.moveTo(0, 0);
          context.arc(0, 0, vars.bearingRadius, -d / 2 - halfPI, d / 2 - halfPI);
          context.lineTo(0, 0);
          context.fillStyle = `rgba(${vars.haloRGB[0]}, ${vars.haloRGB[1]}, ${vars.haloRGB[2]}, 0.25)`;
          context.fill();
        }

        // Triangle
        context.beginPath();
        context.moveTo(-5, -vars.dotRadius);
        context.lineTo(0, -vars.dotRadius - vars.dotStrokeWidth / 2 - 10);
        context.lineTo(5, -vars.dotRadius);
        context.lineTo(-5, -vars.dotRadius);
        context.fillStyle = vars.dotStrokeColor;
        context.fill();
        context.restore();
      }

      // draw dot
      context.beginPath();
      context.arc(cx, cy, vars.dotRadius, 0, Math.PI * 2);
      context.fillStyle = vars.dotFillColor;
      context.strokeStyle = vars.dotStrokeColor;
      context.lineWidth = vars.dotStrokeWidth;
      context.fill();
      context.stroke();

      // update this image's data with data from the canvas
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // continuously repaint the map, resulting in the smooth animation of the dot
      map.triggerRepaint();

      // return `true` to let the map know that the image was updated
      return true;
    },
  };

  const initialize = () => {
    map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

    map.addSource('bluedot', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
    map.addLayer({
      id: 'bluedot-accuracy',
      type: 'circle',
      source: 'bluedot',
      paint: {
        'circle-radius-transition': {
          duration: 1000,
          delay: 0,
        },
        'circle-opacity-transition': {
          duration: 0,
          delay: 0,
        },
        'circle-radius': [
          'interpolate',
          ['exponential', 2],
          ['zoom'],
          0,
          0,
          24,
          ['number', ['feature-state', 'pixelRadiusZ24'], 0],
        ],
        'circle-pitch-alignment': 'map',
        'circle-color': `rgb(${vars.haloRGB[0]}, ${vars.haloRGB[1]}, ${vars.haloRGB[2]})`,
        'circle-opacity': 0.15,
      },
    });

    map.addLayer({
      id: 'bluedot-pulser',
      type: 'symbol',
      source: 'bluedot',
      layout: {
        'icon-rotation-alignment': 'map',
        'icon-image': 'pulsing-dot',
        'icon-pitch-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'text-ignore-placement': true,
        'text-allow-overlap': true,
        'text-font': ['default'], // If your markers aren't showing up, it might be because the font is incorrect. Mapbox silently shows nothing if the font fails to load.
      },
      paint: {
        'text-color': '#ffffff',
      },
    });
  };
  initialize();
}

export default initBluedot;
