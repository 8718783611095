import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import config from '../../../aws-exports';
import { compareVersion } from '../../utils/helpers';
import { readVersionNumber } from '../../utils/nativeInfoUtils';
import ForceUpdateView from './ForceUpdateView';

interface ForceUpdateProps {
  children: React.ReactElement;
}

type ForceUpdateJsonResponse = {
  app: string;
  minVersion: string;
  latestVersion: string;
  blacklistedVersions: string[];
};

const ForceUpdate: React.FC<ForceUpdateProps> = ({ children }) => {
  const [isOutdated, setIsOutdated] = useState(false);

  const fetchForceUpdateData = async () => {
    try {
      /* when running in dev the version can be updated at:
       * 1. Web app.json
       * 2. Android android/app/build.gradle
       * 3. iOS in XCode at the General-tab
       */
      const APP_DEV_VERSION = '1.6.0';
      const currentAppVersion = process.env.NODE_ENV === 'development' ? APP_DEV_VERSION : readVersionNumber();
      const url = config.aws_rest_api_url + '/version';
      if (url) {
        const forceUpdateResponse: AxiosResponse<ForceUpdateJsonResponse> = await axios.get(url);
        /* example:
      {
        app: "biketti-pod-app",
        minVersion: "1.1.2",
        latestVersion: "1.1.6",
        blacklistedVersions: ["1.1.3", "1.1.5"],
      };
      */
        if (forceUpdateResponse) {
          const { blacklistedVersions, minVersion } = forceUpdateResponse.data;
          /**
           * -1 currentAppVersion is smaller than minVersion
           * 0 currentAppVersion is same as minVersion
           * 1 currentAppVersion is bigger than minVersion
           */
          const currentVersionComparedMinVersion = compareVersion(currentAppVersion, minVersion);
          const isCurrentVersionInListOfBlackListedVersions = blacklistedVersions.includes(currentAppVersion);
          if (isCurrentVersionInListOfBlackListedVersions === true) {
            setIsOutdated(true);
          } else if (currentVersionComparedMinVersion === -1) {
            setIsOutdated(true);
          } else {
            setIsOutdated(false);
          }
        }
      }
    } catch (error) {
      console.warn('Force data fetching error', error);
      setIsOutdated(false);
    }
  };

  useEffect(() => {
    fetchForceUpdateData();
  }, []);

  return (
    <>
      <ForceUpdateView visible={isOutdated} />
      {children}
    </>
  );
};

export default ForceUpdate;
