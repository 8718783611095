import * as React from 'react';
import { IconButton, Text, TouchableRipple, useTheme } from 'react-native-paper';
import { ButtonProps, ViewProps } from 'react-native';
import { UiDefaultIconName, UiIcon, uiIconSourceGenerator } from './SimpleListIcon';

type FilterButtonProps = {
  icon: UiDefaultIconName | JSX.Element;
  resetVisible?: boolean;
  onResetPress?: () => void;
  // disabled shouldn't need to defined here, but there
  // is some inconsistency between the ButtonProps
  // and the props TouchableRipple takes, mainly
  // buttonProps says it can be null, and
  // touchableripple doesn't accept that.
  disabled?: boolean;
} & ViewProps &
  ButtonProps;
export const FilterButton: React.FC<FilterButtonProps> = ({
  icon,
  style,
  title,
  resetVisible,
  onResetPress,
  ...other
}) => {
  const { roundness, colors } = useTheme();
  return (
    <TouchableRipple
      style={[
        {
          borderRadius: roundness,
          flexDirection: 'row',
          alignItems: 'center',
          minHeight: 36,
          flex: 1,
          backgroundColor: colors.background,
        },
        style,
      ]}
      {...other}
      accessibilityRole="button"
    >
      <>
        {typeof icon === 'string' ? (
          <UiIcon
            name={icon}
            size={16}
            color={resetVisible ? colors.primary : colors.onSecondaryContainer}
            style={{ margin: 10 }}
          />
        ) : (
          icon
        )}
        <Text ellipsizeMode="tail" numberOfLines={1} style={{ color: colors.onBackground, flex: 1 }}>
          {title}
        </Text>
        {resetVisible ? (
          <IconButton
            onPress={onResetPress}
            iconColor={colors.primary}
            size={16}
            style={{ margin: 0 }} // remove default margin
            icon={uiIconSourceGenerator({ name: 'close' })}
            accessibilityRole={'link'} // button inside button is forbidden, should really be split so we don't nest buttons
          />
        ) : null}
      </>
    </TouchableRipple>
  );
};
