import { addSeconds, isTomorrow, isToday } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { HelperText, useTheme } from 'react-native-paper';
import { useMutation, useQueryClient } from 'react-query';
import type { ApplePayError, ConfirmPaymentError, GooglePayError, StripeError } from '@stripe/stripe-react-native';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { Alert } from 'react-native';
import { checkNotifications } from 'react-native-permissions';
import { useI18n } from '../../context/I18nContext';
import { capitalizeFirstLetter, flattenDict, calculateSecurityDepositInCents } from '../../utils/helpers';
import { cancelReservation, createReservation, invalidateReservationData } from '../../apis/reservationApis';
import { Pod, PaymentMethodOption } from '../../types/misc.types';
import { CANNOT_BOOK } from '../../utils/errorCodes';
import { useStripe, usePlatformPay } from '../utils/stripe';
import ConfirmationRow from '../components/ConfirmationRow';
import { UseCreditsRow } from '../components/UseCreditsRow';
import { AmountDueRow } from '../components/AmountDueRow';
import { useNowMinutes } from '../../hooks/useNow';
import { useMyProfileQuery } from '../../apis/appsyncApis';
import Container from '../../components/Container';
import { NavigationDialog } from '../../screens/CommonDialogs';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { MapStackRouteParams } from '../../navigators/MapStackNavigator';
import { CardPaymentMethod, useActivePayment } from '../MapViewActivePaymentContext';
import { useGetSpaceInfoWithPricing } from '../paymentApis';
import { useMapViewFilter } from '../../context/MapViewFilterContext';
import { isAfterOrEqual } from '../../screens/MapView/PodInformationCard';
import { RESERVED_NOTIFICATION_KEYS, useMapViewBanner } from '../../context/MapViewBannerContext';
import { screenNames } from '../../navigators/screenNames';
import { trackMakeReservation } from '../../utils/mixpanelUtils';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import * as Sentry from '../../components/sentry/sentry';
import { PodStatus, Reservation, ReservationStatus } from '../../types/appsync-types';

export type ReservationFailureHandler = (
  type: 'PAYMENT' | 'CANCELLED' | 'UNEXPECTED' | 'TIMESLOT',
  data: Reservation | undefined | null,
  stripeError?: StripeError<ConfirmPaymentError | GooglePayError | ApplePayError>,
) => void;

export type ReservationSuccessHandler = (
  res: Reservation,
  space: Pod,
  moneySpent: number,
  creditsSpent: number,
  currency: string,
) => void;

export interface ConfirmReservationDialogRouteParams {
  space: Pod;
}

type Props = CompositeScreenProps<
  StackScreenProps<MapStackRouteParams, 'ConfirmReservationDialog'>,
  AppDrawerScreenProp
>;

function getTotalPaymentAmountInCents(reservation: Reservation) {
  return reservation.depositAmountInCents! + reservation.paymentAmountInCents!;
}

export function ConfirmReservationDialog({ navigation, route }: Props) {
  const { activePaymentMethod, isActivePaymentMethodLoading } = useActivePayment();
  const { colors } = useTheme();
  const mp = useMixpanel();
  const stripe = useStripe();
  const { confirmPlatformPayPayment } = usePlatformPay();
  const queryClient = useQueryClient();
  const { I18n, formatDate, formatDateRange, formatDuration, formatCurrency, formatRelative, formatDurationBetween } =
    useI18n();
  const myProfileQuery = useMyProfileQuery();
  const { displayNotification } = useMapViewBanner();
  const creditBalance = myProfileQuery.data?.data?.getMyProfile?.creditBalance ?? undefined;
  const creditCurrency = myProfileQuery.data?.data?.getMyProfile?.currency?.toLowerCase();
  const { space } = route.params;
  const [useCredits, setUseCredits] = useState<boolean>(true);
  const [preReservationId, setPreReservationId] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const now = useNowMinutes();
  const { filter } = useMapViewFilter();

  const currentFilter: { from: string; to: string } = useMemo(() => {
    return {
      from: filter.date.toISOString(),
      to: addSeconds(filter.date, filter.durationInSecond).toISOString(),
    };
  }, [filter.durationInSecond, filter.date]);

  const podInformationQuery = useGetSpaceInfoWithPricing({
    input: {
      ...currentFilter,
      spaceId: space.localRef!,
    },
  });

  const priceInfo = podInformationQuery.data?.data?.estimatePriceAndHoldAmount;
  const securityDepositInCents = useMemo(() => {
    return calculateSecurityDepositInCents(space, myProfileQuery.data?.data?.getMyProfile)[0];
  }, [myProfileQuery.data?.data?.getMyProfile, space]);

  const [moneyToSpend, creditsToSpend] = useMemo(() => {
    if (priceInfo && priceInfo.price !== null && priceInfo.price !== undefined && priceInfo.currency) {
      if (useCredits && creditCurrency === priceInfo.currency.toLowerCase() && creditBalance) {
        const crd = Math.min(creditBalance, priceInfo.price);
        return [priceInfo.price - crd, crd];
      }
      return [priceInfo.price, 0];
    }
    return [undefined, undefined];
  }, [creditBalance, creditCurrency, priceInfo, useCredits]);

  const podCurrency = useMemo(() => {
    return priceInfo?.currency?.toUpperCase() ?? 'EUR';
  }, [priceInfo?.currency]);

  const isAvailable: boolean = useMemo(() => {
    const avail = podInformationQuery.data?.data?.getPoisByID?.availabilityStatus;
    if (avail && avail.status === PodStatus.FREE && avail.nextFreeEnd) {
      const freeEndExclusive = new Date(avail.nextFreeEnd);
      const selectedEndTime = addSeconds(filter.date, filter.durationInSecond);
      return avail.status === PodStatus.FREE && isAfterOrEqual(freeEndExclusive, selectedEndTime);
    }
    return false;
  }, [filter.date, filter.durationInSecond, podInformationQuery.data?.data?.getPoisByID?.availabilityStatus]);

  const canUserMakeBooking = isAvailable && moneyToSpend !== undefined && creditsToSpend !== undefined;

  const handleReservationFailed: ReservationFailureHandler = useCallback(
    (type, makeAReservation, stripeError) => {
      mp?.getPeople().increment('Reservation failures', 1);
      if (type === 'CANCELLED') {
        mp?.track('Reservation creation cancelled', flattenDict('reservation', makeAReservation));
        setErrorMessage(
          `${I18n.t('mapview.podInfoCard.reservationCancelled.title')}: ${I18n.t(
            'mapview.podInfoCard.reservationCancelled.description',
          )}`,
        );
      } else if (type === 'TIMESLOT') {
        mp?.track('Reservation creation failed', { reason: 'already_booked' });
        setErrorMessage(
          `${I18n.t('mapview.modalConfirmation.cannotBookTitle')}: ${I18n.t(
            'mapview.modalConfirmation.cannotBookOverlapDescription',
          )}`,
        );
      } else if (stripeError) {
        mp?.track('Reservation creation failed', flattenDict('error', stripeError));
        setErrorMessage(stripeError.localizedMessage ?? stripeError.message);
      } else {
        mp?.track('Reservation creation failed', flattenDict('reservation', makeAReservation));
        setErrorMessage(`${I18n.t('mapview.banner.bookingFailMainContent')}`);
      }
    },
    [mp, I18n],
  );

  const cancelReservationMutation = useMutation(
    async (input: Parameters<typeof cancelReservation>) => {
      return cancelReservation(...input);
    },
    {
      onSuccess(data) {
        console.log('Cancel success', data);
      },
      onError(err) {
        console.log('Cancel fail', err);
      },
    },
  );
  const onReservationSucceeded = useCallback(
    (
      makeAReservation: Reservation,
      selectedSpace: Pod,
      moneyToUse: number,
      creditsToUse: number,
      currencyUsed: string,
    ) => {
      trackMakeReservation(mp, makeAReservation, selectedSpace, moneyToUse, creditsToUse, currencyUsed);

      displayNotification({
        notificationKeys: [RESERVED_NOTIFICATION_KEYS.BOOKING_SUCCEED],
        helperContent: I18n.t('mapview.banner.bookingCreatedHelper'),
        mainContent: I18n.t('mapview.banner.bookingCreatedMainContent', {
          time:
            `${formatDurationBetween(now, new Date(makeAReservation?.from!))} ` +
            `(${formatDate(new Date(makeAReservation?.from!), 'time', makeAReservation?.pois?.building?.timezone)})`,
        }),
        actions: [
          {
            label: I18n.t('mapview.banner.remindLater'),
            onPress: () => {
              displayNotification(null);
            },
          },
          {
            label: I18n.t('mapview.banner.viewDetails'),
            onPress: () => {
              navigation?.navigate('map', {
                screen: screenNames.BookingDetail,
                params: makeAReservation?.id
                  ? {
                      bookingId: makeAReservation.id,
                    }
                  : undefined,
              });
            },
          },
        ],
        icon: undefined,
      });

      // Sparing a bit time to try to eliminate the async update of reservation
      setTimeout(() => {
        invalidateReservationData(queryClient);
      }, 1000);
      checkNotifications().then((res) => {
        if (res.status !== 'granted') {
          navigation.navigate('MapView', { action: { type: 'CHECK-PERMISSION' } });
        } else {
          navigation.goBack();
        }
      });
    },
    [I18n, displayNotification, formatDate, formatDurationBetween, mp, navigation, now, queryClient],
  );

  const handlePaymentWithGooglePay = useCallback(
    async (createdReservation: Reservation): Promise<void> => {
      try {
        const { error: presentPlatformPaymentError } = await confirmPlatformPayPayment(
          createdReservation?.paymentSecret!,
          {
            googlePay: {
              merchantName: 'Biketti Oy',
              merchantCountryCode: 'FI',
              testEnv: process.env.NODE_ENV === 'development',
              currencyCode: podCurrency ?? 'EUR',
            },
          },
        );
        if (presentPlatformPaymentError) {
          throw presentPlatformPaymentError;
        }
        onReservationSucceeded(
          createdReservation!,
          space,
          getTotalPaymentAmountInCents(createdReservation),
          createdReservation.credit ?? 0,
          createdReservation.currency!,
        );
      } catch (error) {
        console.error('Payment failed', error);
        mp?.getPeople().increment('Payment failures', 1);
        mp?.track('Reservation creation payment failed', {
          ...flattenDict('reservation', createdReservation),
          ...flattenDict('error', error),
        });
        Sentry.captureException(error);
        handleReservationFailed('PAYMENT', createdReservation, error as StripeError<GooglePayError>);
      }
    },
    [confirmPlatformPayPayment, podCurrency, onReservationSucceeded, space, mp, handleReservationFailed],
  );

  const handlePaymentWithCard = useCallback(
    async (createdReservation: Reservation): Promise<void> => {
      try {
        const confirmPaymentResult = await stripe.confirmPayment(createdReservation?.paymentSecret!, {
          paymentMethodType: 'Card',
          paymentMethodData: { paymentMethodId: (activePaymentMethod as CardPaymentMethod).stripeCard.id },
        });
        if (confirmPaymentResult.paymentIntent) {
          onReservationSucceeded(
            createdReservation!,
            space,
            confirmPaymentResult.paymentIntent.amount,
            createdReservation!.credit ?? 0,
            confirmPaymentResult.paymentIntent.currency,
          );
        } else {
          throw confirmPaymentResult.error;
        }
      } catch (error) {
        console.error('Payment failed', error);
        mp?.getPeople().increment('Payment failures', 1);
        mp?.track('Reservation creation payment failed', {
          ...flattenDict('reservation', createdReservation),
          ...flattenDict('error', error),
        });
        Sentry.captureException(error);
        handleReservationFailed('PAYMENT', createdReservation, error as StripeError<ConfirmPaymentError>);
      }
    },
    [stripe, activePaymentMethod, onReservationSucceeded, space, mp, handleReservationFailed],
  );

  const makeAReservationMutation = useMutation(
    async (input: Parameters<typeof createReservation>) => {
      return createReservation(...input);
    },
    {
      async onSuccess(data) {
        setPreReservationId(data.data?.makeAReservation?.id);

        switch (data.data?.makeAReservation?.status) {
          case ReservationStatus.FAILED:
            handleReservationFailed('PAYMENT', data.data?.makeAReservation);
            break;
          case ReservationStatus.CANCELLED:
            handleReservationFailed('CANCELLED', data.data?.makeAReservation);
            break;
          case ReservationStatus.PAYMENT_REQUIRED:
            mp?.track('Reservation creation pending payment', flattenDict('reservation', data.data?.makeAReservation));
            // activePaymentMethod can not be null, because user cannot click payment button without active payment method
            if (!activePaymentMethod) {
              Alert.alert(I18n.t('payment.alert.noActivePaymentMethod'));
              return;
            }
            if (activePaymentMethod.paymentMethodType === PaymentMethodOption.CARD) {
              await handlePaymentWithCard(data.data.makeAReservation!);
            } else {
              await handlePaymentWithGooglePay(data.data.makeAReservation!);
            }
            break;

          case ReservationStatus.RESERVED:
            // Success without payment, i.e. with just credits.
            onReservationSucceeded(
              data.data?.makeAReservation!,
              space,
              data.data.makeAReservation.paymentAmountInCents ?? 0,
              data.data.makeAReservation.credit ?? 0,
              data.data.makeAReservation.currency!,
            );
            break;
          default:
            handleReservationFailed('UNEXPECTED', data.data?.makeAReservation);
            break;
        }
      },
      onError(error: any) {
        /* Could not do pre-payment-booking */
        const errorCode = error?.errors?.[0]?.message;
        if (errorCode === CANNOT_BOOK) {
          handleReservationFailed('TIMESLOT', null);
        } else {
          handleReservationFailed('UNEXPECTED', null);
        }
      },
    },
  );

  const onConfirm = useCallback(
    async (confirmedStartTime: Date, confirmedDurInSecs: number, confirmedCreditsToUse: number | undefined) => {
      const creditsAmount = confirmedCreditsToUse === undefined ? 0 : confirmedCreditsToUse;

      // The actual logic of what to do after this, is in the mutations' success / fail handlers.
      await makeAReservationMutation.mutateAsync([
        {
          input: {
            poisId: space.id,
            from: confirmedStartTime.toISOString(),
            to: addSeconds(confirmedStartTime, confirmedDurInSecs).toISOString(),
          },
          // This mutation uses euro-cents as the unit instead of euros
          usedCredit: creditsAmount * 100,
        },
      ]);
    },
    [space, makeAReservationMutation],
  );

  const handleUserDismissed = useCallback(() => {
    /* User cancelled trying to make a reservation */

    /* If we have a pre-reservation, we cancel it */
    if (preReservationId) {
      cancelReservationMutation.mutate([{ id: preReservationId }]);
    }
    navigation.goBack();
  }, [preReservationId, navigation, cancelReservationMutation]);

  const showInfoDialog = useCallback(() => {
    if (securityDepositInCents && priceInfo?.currency) {
      navigation.navigate('SecurityDepositDialog', {
        space,
        amount: securityDepositInCents / 100,
        currency: priceInfo.currency,
        showCheckbox: false,
        onOk: 'GO_BACK',
      });
    }
  }, [navigation, priceInfo?.currency, securityDepositInCents, space]);

  const userCanPay = useMemo(() => {
    if (isActivePaymentMethodLoading || makeAReservationMutation.isLoading) {
      return undefined;
    }
    if (activePaymentMethod) {
      return true;
    }
    if (securityDepositInCents === 0 && moneyToSpend === 0) {
      return true;
    }
    return false;
  }, [
    activePaymentMethod,
    isActivePaymentMethodLoading,
    makeAReservationMutation.isLoading,
    moneyToSpend,
    securityDepositInCents,
  ]);

  const dateString = useMemo(() => {
    const relativeString =
      isToday(filter.date) || isTomorrow(filter.date)
        ? `${capitalizeFirstLetter(
            formatRelative(filter.date, now, space.building?.timezone, { showDate: true, dropDateIfRelative: true }),
          )} `
        : '';
    return `${relativeString}${formatDate(filter.date, 'dateWithYear-no-tz', space.building?.timezone)}`;
  }, [filter.date, formatDate, formatRelative, now, space.building?.timezone]);

  return (
    <NavigationDialog
      style={{ backgroundColor: colors.elevation.level2 }} // TODO: is this correct?
      title={I18n.t('mapview.modalConfirmation.title')}
      description={''}
      onDismiss={handleUserDismissed}
      actions={[
        {
          title: I18n.t('mapview.modalConfirmation.cancel'),
          onPress: handleUserDismissed,
          disable: makeAReservationMutation.isLoading,
        },
        {
          title: I18n.t('mapview.modalConfirmation.confirm'),
          loading: makeAReservationMutation.isLoading || isActivePaymentMethodLoading,
          disable: !userCanPay || !isAvailable,
          onPress: () => {
            return onConfirm(filter.date, filter.durationInSecond, creditsToSpend);
          },
        },
      ]}
      iconName={'check'}
    >
      {/* Date */}
      <ConfirmationRow title={dateString} iconName={'calendar'} />

      {/* Time */}
      <ConfirmationRow
        title={`${formatDateRange(
          filter.date,
          addSeconds(filter.date, filter.durationInSecond),
          'time',
          space.building?.timezone,
        )} (${formatDuration(filter.durationInSecond, 'seconds')})`}
        iconName={'clock'}
      />

      {/* Money */}
      <ConfirmationRow
        iconName={'wallet'}
        title={
          securityDepositInCents
            ? `${formatCurrency(priceInfo?.price, priceInfo?.currency)} ${I18n.t('mapview.modalConfirmation.deposit', {
                amount: formatCurrency(securityDepositInCents / 100, priceInfo?.currency),
              })}`
            : formatCurrency(priceInfo?.price, priceInfo?.currency)
        }
        isLoading={podInformationQuery.isLoading}
      />

      {/* Credits */}
      <UseCreditsRow
        balance={creditBalance}
        currency={creditCurrency}
        disabled={creditCurrency !== priceInfo?.currency?.toLowerCase()}
        value={useCredits}
        onValueChange={setUseCredits}
        errorText={
          priceInfo?.currency && creditCurrency !== priceInfo?.currency?.toLowerCase()
            ? I18n.t('mapview.modalConfirmation.creditCurrencyNotMatching')
            : undefined
        }
      />

      {/* Amount due */}
      <AmountDueRow
        canUserMakeBooking={canUserMakeBooking}
        moneyToSpend={moneyToSpend}
        currency={priceInfo?.currency}
        securityDeposit={(securityDepositInCents ?? 0) / 100}
        onInfoPressed={showInfoDialog}
      />

      {/* Error text */}
      <Container>
        <HelperText type={'error'} visible={!!errorMessage}>
          {errorMessage}
        </HelperText>
      </Container>
    </NavigationDialog>
  );
}
