const Images = <const>{
  iconAppLogo: require('./icons/ic_biketti.svg').default,
  iconApp: require('./icons/biketti-logo-white-horizontal.svg').default,
  iconProfile: require('./icons/ic_profile.svg').default,
  iconError: require('./icons/ic_error.svg').default,
  iconInfo: require('./icons/ic_information_circle_outline.svg').default,
  iconLogout: require('./icons/ic_logout.svg').default,
  iconGooglePayMark: require('./icons/google-pay-mark_800.svg').default,
  imageLeftPerson: require('./images/img_left_person.svg').default,
  imageLeftPersonLarge: require('./images/img_left_person_large.svg').default,
  imageRightPerson: require('./images/img_right_person.svg').default,
  imageRightPersonLarge: require('./images/img_right_person_large.svg').default,
  imageMask: require('./images/img_mask.svg').default,
  BIK500x500px: require('./images/BIK-500x500px.png'),
  imageSlide2: require('./images/img_slide2.png'), // todo change to svg
  imageSlide3: require('./images/img_slide3.png'), // todo change to svg
  imageSlide4: require('./images/img_slide4.png'), // todo change to svg
  imageSlide5: require('./images/img_slide5.png'), // todo change to svg
  ImageScanTarget: require('./images/img_scan_target.svg').default,
  ImageCard: require('./icons/ic_card.svg').default,
  playStore: require('./images/img_download_google_play.svg'),
  appStore: require('./images/img_download_app_store.svg'),
  storeIcon: require('./icons/storeIcon.svg'),
  storeIcon_512: require('./images/storeIcon_512.png'),
  CYKLON500x500px: require('./images/CYKLON-500x500px.png'),
};

export default Images;
