import React, { useCallback, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { ActivityIndicator, useTheme } from 'react-native-paper';
import * as Linking from 'expo-linking';
import { CompositeScreenProps, StackActions } from '@react-navigation/native';
import { WebViewNavigation } from 'react-native-webview';
import { WebViewRenderProcessGoneEvent, WebViewTerminatedEvent } from 'react-native-webview/lib/WebViewTypes';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';
import * as Sentry from '../components/sentry/sentry';
import { screenNames } from '../navigators/screenNames';
import WebView from '../components/webview/WebView';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { HelpStackRouteParams } from '../navigators/HelpStackNavigator';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import { NewsStackRouteParams } from '../navigators/NewsStackNavigator';

export type OnlinePageRouteParams = {
  url: string;
};

type Props =
  | CompositeScreenProps<
      StackScreenProps<
        HelpStackRouteParams,
        'FAQ' | 'OnlineSubPage' | 'OpenSourceSoftwareLicenses' | 'TermsOfService' | 'PrivacyPolicy'
      >,
      AppDrawerScreenProp
    >
  | CompositeScreenProps<StackScreenProps<MapStackRouteParams, 'SpaceDescription'>, AppDrawerScreenProp>
  | CompositeScreenProps<StackScreenProps<NewsStackRouteParams, 'News'>, AppDrawerScreenProp>;

export default function OnlinePage({ navigation, route, html }: Props & { html?: string }) {
  const { colors } = useTheme();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [reloadKey, setReloadKey] = useState<number>(0);

  const url = useMemo(() => {
    return route.params?.url ?? '';
  }, [route.params?.url]);
  const webview = useRef<WebView>(null);

  const onNav = useCallback(
    (ev: WebViewNavigation) => {
      if (ev.url !== url && ev.url !== 'about:blank') {
        webview.current?.stopLoading();
        const initialUrl = new URL(url);
        const newUrl = new URL(ev.url);

        if (initialUrl.hostname === newUrl.hostname) {
          // Same host, follow it
          navigation.dispatch(StackActions.push(screenNames.OnlineSubPage, { title: ev.title, url: ev.url }));
        } else if (newUrl.hostname === 'local') {
          // Web page can trigger a navigation within the app
          const folders = newUrl.pathname.split('/');
          const screenName = folders[folders.length - 1];
          if (screenName === 'SpaceDescription') {
            (navigation as StackNavigationProp<MapStackRouteParams>).navigate('SpaceDescription');
          } else if (screenName === 'News') {
            (navigation as StackNavigationProp<NewsStackRouteParams>).navigate('News');
          } else {
            (navigation as StackNavigationProp<HelpStackRouteParams>).navigate(screenName as any);
          }
          // Not sure if this reload is needed, got it from https://itnext.io/using-webview-with-react-navigation-43344217111e
          webview.current?.reload();
        } else {
          // External link, open in external app.
          Linking.openURL(ev.url);
        }
      }
    },
    [webview, navigation, url],
  );

  const renderLoading = useCallback(() => {
    return (
      <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator animating={true} color={colors.secondary} />
        </View>
      </View>
    );
  }, [colors]);

  const handleLoadComplete = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const handleRenderProcessGone = useCallback((evt: WebViewRenderProcessGoneEvent) => {
    Sentry.captureException(new Error('WebViewRenderProcessGoneEvent: ' + evt.toString()));
    setReloadKey((prev) => prev + 1);
    setIsLoaded(false);
  }, []);
  const handleContentProcessDidTerminate = useCallback((evt: WebViewTerminatedEvent) => {
    Sentry.captureException(new Error('WebViewTerminatedEvent: ' + evt.toString()));
    setReloadKey((prev) => prev + 1);
    setIsLoaded(false);
  }, []);

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <WebView
        style={{ opacity: isLoaded ? 1 : 0 }}
        source={html ? { html } : { uri: url }}
        bounces={false}
        key={reloadKey}
        onNavigationStateChange={onNav}
        ref={webview}
        onLoadEnd={handleLoadComplete}
        onRenderProcessGone={handleRenderProcessGone}
        onContentProcessDidTerminate={handleContentProcessDidTerminate}
      />
      {isLoaded ? null : renderLoading()}
    </View>
  );
}
