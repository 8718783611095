import React, { FC, useState } from 'react';
import { Image } from 'react-native';
import Images from '../../../assets/images';
import { useI18n } from '../../context/I18nContext';
import Slide from './Slide';

interface ImageSizes {
  leftPersonSize: { height: number; width: number };
  rightPersonSize: { height: number; width: number };
  podSize: { height: number; width: number };
}

const Slide1: FC = () => {
  const { I18n } = useI18n();

  // height / width
  const leftPersonAspectRatio = 347 / 217;
  const rightPersonAspectRatio = 238 / 116;

  const [imgSizes, setImgSizes] = useState<ImageSizes>({
    leftPersonSize: { width: 0, height: 0 },
    rightPersonSize: { width: 0, height: 0 },
    podSize: { width: 0, height: 0 },
  });

  function handleImageSizing(containerHeight: number) {
    const leftPersonHeight = containerHeight * 0.8;
    const leftPersonWidth = leftPersonHeight / leftPersonAspectRatio;
    const rightPersonHeight = containerHeight * 0.5;
    const rightPersonWidth = rightPersonHeight / rightPersonAspectRatio;
    const podHeight = containerHeight;
    const podWidth = podHeight;

    setImgSizes({
      leftPersonSize: {
        width: leftPersonWidth,
        height: leftPersonHeight,
      },
      rightPersonSize: {
        width: rightPersonWidth,
        height: rightPersonHeight,
      },
      podSize: {
        width: podWidth,
        height: podHeight,
      },
    });
  }

  return (
    <Slide
      title={I18n.t('intro.step1Title')}
      description={I18n.t('intro.step1Description')}
      bottomAreaOnLayout={(evt) => {
        handleImageSizing(evt.nativeEvent.layout.height);
      }}
    >
      <Image
        source={Images.BIK500x500px}
        height={imgSizes.podSize.height}
        width={imgSizes.podSize.width}
        style={{
          position: 'absolute',
          right: 1,
          zIndex: 1,
          height: imgSizes.podSize.height,
          width: imgSizes.podSize.width,
        }}
      />
    </Slide>
  );
};

export default Slide1;
