import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import * as Sentry from '@sentry/react-native';
import { Button, useTheme } from 'react-native-paper';
import { useMutation } from 'react-query';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../../context/I18nContext';
import Typography from '../../components/Typography';
import { screenNames } from '../../navigators/screenNames';
import Images from '../../../assets/images';
import GradientButton from '../../components/GradientButton';
import { useAuth } from '../../context/AuthContext';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { useModal } from '../../context/ModalContext';
import Switch from '../../components/ui/Switch';
import TextModal, { ModalAlertType } from '../../components/modal/TextModal';
import Container from '../../components/Container';
import ScrollablePage from '../../components/ui/ScrollablePage';
import PhoneNumberInput from '../../components/ui/PhoneNumberInput';
import { NotAuthenticatedStackRouteParams } from '../../navigators/RootNavigator';
import { SignupResponse } from '../../types/appsync-types';
import { safeJsonStringify } from '../../utils/helpers';

const INVALID_PHONE_NUMBER_MESSAGE_CODE = 'Invalid_phone_number';

export type LoginScreenRouteParams = {
  invitationCode: string;
};

type Props = StackScreenProps<NotAuthenticatedStackRouteParams, 'LoginScreen'>;

export const PhoneLoginScreen: React.FC<Props> = ({ navigation, route }) => {
  const { colors, fonts } = useTheme();
  const mp = useMixpanel();
  const [isAgreed, setIsAgreed] = useState(false);
  const modal = useModal();
  const [numberIsValid, setNumberIsValid] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [invitationCode, setInvitationCode] = useState('');

  const { I18n } = useI18n();
  const { signUp, loginAsGuest } = useAuth();

  const signUpWithPhone = useMutation(
    async (phone: string) => {
      if (phone.length < 6 || !numberIsValid) {
        throw new Error(INVALID_PHONE_NUMBER_MESSAGE_CODE);
      }
      const user = await signUp(phoneNumber, invitationCode);
      mp?.track('Sign in');
      mp?.getPeople().increment('Sign-ins', 1);
      return user;
    },

    {
      onError(error: any) {
        const errorCode = error?.message;
        switch (errorCode) {
          case SignupResponse.ERR_CODE_NOT_EXISTED:
            mp?.track('Invite code failed', {
              reason: 'No such invite code',
              invitationCode,
            });
            mp?.getPeople().increment('Invite code failed', 1);
            modal.openModal({
              content: (
                <TextModal
                  type={ModalAlertType.ERROR}
                  title={I18n.t('phoneLogin.unknownCode.title')}
                  description={I18n.t('phoneLogin.unknownCode.description')}
                  buttons={[{ title: I18n.t('general.dismiss'), onPress: modal.closeModal }]}
                />
              ),
            });
            break;
          case SignupResponse.ERR_NOT_FIRST_TIME:
            mp?.track('Invite code failed', {
              reason: 'User already signed up',
              invitationCode,
            });
            mp?.getPeople().increment('Invite code failed', 1);
            modal.openModal({
              content: (
                <TextModal
                  type={ModalAlertType.ERROR}
                  title={I18n.t('phoneLogin.cannotUseCode.title')}
                  description={I18n.t('phoneLogin.cannotUseCode.description')}
                  buttons={[{ title: I18n.t('general.dismiss'), onPress: modal.closeModal }]}
                />
              ),
            });
            break;
          case SignupResponse.ERR_BAD_REQUEST:
          default:
            mp?.track('Login/Signup failed', {
              reason: 'Unexpected error',
              invitationCode,
            });
            Sentry.captureException(new Error('Signup error: ' + safeJsonStringify(error)));
            modal.openModal({
              content: (
                <TextModal
                  type={ModalAlertType.ERROR}
                  title={I18n.t('phoneLogin.unexpectedError.title')}
                  description={I18n.t('phoneLogin.unexpectedError.description')}
                  buttons={[{ title: I18n.t('general.dismiss'), onPress: modal.closeModal }]}
                />
              ),
            });
            break;
        }
      },
    },
  );

  useEffect(() => {
    if (typeof route.params?.invitationCode === 'string') {
      setInvitationCode(route.params?.invitationCode);
    }
  }, [route.params?.invitationCode]);

  useEffect(() => {
    if (signUpWithPhone.isSuccess) {
      signUpWithPhone.reset();
      navigation.navigate(screenNames.ConfirmCode, { phoneNumber });
    }
  }, [navigation, phoneNumber, signUpWithPhone]);

  const handleSignIn = useCallback(() => {
    mp?.track('Sign In', {
      phonenumber: phoneNumber,
    });
    signUpWithPhone.mutate(phoneNumber);
  }, [signUpWithPhone, phoneNumber, mp]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          mode="text"
          color={colors.onSurface}
          onPress={() => {
            loginAsGuest();
          }}
        >
          {I18n.t('general.notNow')}
        </Button>
      ),
    });
  }, [navigation, colors.secondary, I18n, loginAsGuest, colors.onSurface]);

  const renderFooter = useCallback(() => {
    return (
      <Container>
        {/* Text and switch */}
        <View style={[styles.row, { marginBottom: 15 }]}>
          <View style={[styles.row, { flexWrap: 'wrap', flex: 1, marginRight: 20 }]}>
            <Typography variant="body2" color={colors.onBackground}>
              {I18n.t('phoneLogin.confirmText')}{' '}
            </Typography>
            <Pressable onPress={() => navigation.navigate(screenNames.ToS)}>
              <Typography
                color={colors.onBackground}
                variant="body2"
                style={[fonts.labelMedium, { textDecorationLine: 'underline' }]}
              >
                {I18n.t('phoneLogin.termsAndCondition')}
              </Typography>
            </Pressable>
            <Typography variant="body2" color={colors.onBackground} style={[fonts.labelMedium]}>
              {' & '}
            </Typography>
            <Pressable onPress={() => navigation.navigate(screenNames.PP)}>
              <Typography
                color={colors.onBackground}
                variant="body2"
                style={[fonts.labelMedium, { textDecorationLine: 'underline' }]}
              >
                {I18n.t('phoneLogin.privacyPolicy')}
              </Typography>
            </Pressable>
          </View>
          <Switch
            style={{ alignSelf: 'flex-end' }}
            onValueChange={() => setIsAgreed((prev) => !prev)}
            value={isAgreed}
          />
        </View>

        {/* Button */}
        <GradientButton
          disabled={phoneNumber.length < 6 || !numberIsValid || !isAgreed}
          loading={signUpWithPhone.isLoading}
          onPress={handleSignIn}
        >
          {I18n.t('general.continue')}
        </GradientButton>
      </Container>
    );
  }, [I18n, colors, fonts, handleSignIn, isAgreed, navigation, numberIsValid, phoneNumber, signUpWithPhone.isLoading]);

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('phoneLogin.title'),
        description: I18n.t('phoneLogin.subtitle'),
        titleContent: (
          <View>
            <Images.iconApp style={{ width: '60%', height: 40, position: 'absolute' }} width={'60%'} height={40} />
          </View>
        ),
      }}
      footerContent={renderFooter()}
    >
      <Container>
        <PhoneNumberInput
          hasError={signUpWithPhone.isError}
          onChange={(num, valid) => {
            setPhoneNumber(num);
            setNumberIsValid(valid);
          }}
        />

        {!!invitationCode && (
          <Typography variant={'body2'} color={colors.secondary}>
            {I18n.t('phoneLogin.usingInvitationCode', { code: invitationCode })}
          </Typography>
        )}
        <Button
          style={{ alignSelf: 'flex-end' }}
          textColor={colors.primary}
          onPress={() => {
            signUpWithPhone.reset();
            navigation.navigate(screenNames.EnterInvitationCode, { invitationCode });
          }}
        >
          {I18n.t(invitationCode ? 'phoneLogin.changeInvitationCode' : 'phoneLogin.addInvitationCode')}
        </Button>
      </Container>
    </ScrollablePage>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
});
