import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import AppFeedback from '../screens/AppFeedback';
import { screenNames } from './screenNames';
import AboutApp from '../screens/AboutApp';
import SpaceFeedback from '../screens/SpaceFeedback';
import { HelpView } from '../screens/Help';
import { useI18n } from '../context/I18nContext';
import OnlinePage, { OnlinePageRouteParams } from '../screens/OnlinePage';
import OSSLicense from '../screens/OSSLicense';
import { AppbarScreenOptions, DefaultScreenOptions } from '../components/NavigatorAppbar';
// import { faqHtml } from './placeholderHtmls';

export type HelpStackRouteParams = {
  [screenNames.FAQ]: undefined | OnlinePageRouteParams;
  [screenNames.ToS]: undefined | OnlinePageRouteParams;
  [screenNames.PP]: undefined | OnlinePageRouteParams;
  [screenNames.OSSLicense]: undefined;
  [screenNames.OnlineSubPage]: undefined;
  [screenNames.Help]: undefined;
  [screenNames.AppFeedback]: undefined;
  [screenNames.SpaceFeedback]: undefined;
  [screenNames.AboutApp]: undefined;
  [screenNames.SpaceFeedback]: undefined;
  [screenNames.SpaceFeedback]: undefined;
};

const Stack = createStackNavigator<HelpStackRouteParams>();

// function FaqHtmlPage(props: any) {
//   return <OnlinePage {...props} html={faqHtml} />;
// }

export function HelpStackNavigator() {
  const { I18n } = useI18n();

  return (
    <Stack.Navigator initialRouteName={screenNames.Help} screenOptions={DefaultScreenOptions}>
      {/* <Stack.Screen
        name={screenNames.FAQ}
        component={FaqHtmlPage}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('help.faq.title'),
        }}
        initialParams={{
          url: I18n.t('help.faq.url'),
        }}
      /> */}
      <Stack.Screen
        name={screenNames.ToS}
        component={OnlinePage}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('help.tos.title'),
        }}
        initialParams={{
          url: I18n.t('help.tos.url'),
        }}
      />
      <Stack.Screen
        name={screenNames.PP}
        component={OnlinePage}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('help.pp.title'),
        }}
        initialParams={{
          url: I18n.t('help.pp.url'),
        }}
      />
      <Stack.Screen
        name={screenNames.OSSLicense}
        component={OSSLicense}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('help.oss.title'),
        }}
      />
      <Stack.Screen
        name={screenNames.OnlineSubPage}
        component={OnlinePage}
        options={{
          ...AppbarScreenOptions,
          title: '...',
        }}
      />

      <Stack.Screen
        name={screenNames.Help}
        component={HelpView}
        options={{
          ...DefaultScreenOptions,
          title: I18n.t('help.headerTitle'),
        }}
      />

      <Stack.Screen
        name={screenNames.AppFeedback}
        component={AppFeedback}
        options={{
          title: I18n.t('help.appFeedback.headerTitle'),
        }}
      />

      <Stack.Screen
        name={screenNames.SpaceFeedback}
        component={SpaceFeedback}
        options={{
          title: I18n.t('help.spaceFeedback.headerTitle'),
        }}
      />

      <Stack.Screen
        name={screenNames.AboutApp}
        component={AboutApp}
        options={{
          title: I18n.t('help.about.headerTitle'),
        }}
      />
    </Stack.Navigator>
  );
}
