import { generateIndoorTheme } from './IndoorTheme';

export type AreaColorType = {
  fill?: string;
  outline?: string;
  fill3d?: string;
};
export type LineColorType = {
  lines?: string;
};
export type TextColorType = {
  halo?: string;
  text?: string;
};

export type FillType = {
  start: number;
  end: number;
  opacity?: number;
  opacity3d?: number;
  height3d?: number;
  pattern?: string;
};

export type LineType = {
  start: number;
  end?: number;
};

export type TextType = {
  start: number;
  end: number;
  sizeMin?: number;
  sizeMax?: number;
};

export type IconType = {
  start: number;
  end?: number;
};

const primaryPoiClasses = [
  'food',
  'retail',
  'cafe',
  'category_cafe',
  'category_bar',
  'category_restaurant',
  'category_food',
  'category_retail',
  'category_retail_flower',
  'category_grocery',
  'category_fun',
  'service_movie_theatre',
  'category_custom',

  'category_pub',
  'category_games',
  'category_theatre',
  'category_wine_shop',
  'category_library',

  'infrastructure_entrance',
  'infrastructure_exit',
  'infrastructure_disabled_access',

  'category_emergency_room',

  'category_kitchen',
  'transport_skytrain',
];
const secondaryPoiClasses = [
  'service_toilet',
  'toilet',
  'category_other',
  'category_accommodation',

  'transport_train',
  'transport_taxi',
  'service_airline_lounge',
  'service_atm',
  'service_baby_care',
  'service_baggage_storage',
  'service_clinic',
  'service_printer',
  'service_shower',
  'service_cloakroom',
  'category_gate_airplane',
  'service_information',

  'infrastructure_escalator',
  'infrastructure_stairs',
  'infrastructure_lift',
  'infrastructure_lighting',

  'service_fire_extinguisher',
  'service_fire_hose',
  'service_first_aid',
  'service_emergency_exit',
  'service_device_charging',
  'infrastructure_electricity',
  'infrastructure_led',
  'service_phone',
  'service_tools',
  'service_prayer_room',
  'category_track_order',

  'category_news',
  'category_art',

  'service_toilet_disabled',

  'category_ticket_sale',

  'category_security',
  'infrastructure_ramp',
  'service_airport_transfer',
  'service_baggage_claim',
  'service_checkin',
  'service_immigration',
  'service_internet',
  'service_laptop_access',
  'service_lodging',
  'service_meditation_room',
  'service_money_exchange',
  'service_post',
  'service_rest_area',
  'service_smoking_area',
  'service_tv_lounge',
  'service_wifi',
  'transport_pickup_car',
  'transport_bus',

  'category_vending_machine',

  'service_registration',
  'category_waiting_room',
  'category_appointment',
  'category_laboratory',

  'service_toilet_women',
  'service_toilet_men',

  'category_reception',
  'category_computer_room',
  'category_classroom',

  'service_orthosis',
  'category_gymnasium',
  'category_exercise',
  'service_stroller_park',

  // "infrastructure_carpark",
  'infrastructure_disabled_parking',

  'infrastructure_door_locked',
  'infrastructure_door',

  'category_workspace_group',
  'service_post_package',
  'category_recycle_bottle',

  'service_information_screen',

  'category_recycle',

  'service_touch_screen',
  'service_defibrillator',
  'category_clothes',

  'service_revive_equipment',
  'service_pneumatic_tube',
];

const tertiaryPoiClasses = [
  'room',
  'category_room',

  'category_conference',
  'category_workspace',
  'category_workshop',
  'category_relieve',
  'category_office',
  'category_auditorium',

  'category_meetings',
  'category_meeting_room',

  'stand',
  'stage',

  'category_workspace_quiet',

  'service_desk',
  'service_desk_hot',

  'category_work_station',
  'category_work_area',
];

const detailPoiClasses = ['plant', 'furniture', 'stairs', 'infrastructure_travelator', 'infrastructure_railing'];

export type IndoorSettings = {
  underground: {
    fill: FillType;
  };
  // OutdoorAndCoverCategories
  outdoor: {
    fill: FillType;
    text: TextType;
    icon: IconType;
    lines: LineType;
  };
  cover: {
    fill: FillType;
    text: TextType;
  };

  // CarparkDetails
  garden: {
    fill: FillType;
  };
  water: {
    fill: FillType;
  };
  marking: {
    fill: FillType;
    lines: LineType;
  };
  kerb: {
    fill: FillType;
    lines: LineType;
  };
  waterAndGrass: {
    fill: FillType;
    lines: LineType;
  };

  // InfrastructureCategories
  floor: {
    fill: FillType;
  };
  wall: {
    fill: FillType;
  };
  texts: {
    fill: FillType;
    text: TextType;
  };

  // WingAndDepartmenCategories
  wing: {
    fill: FillType;
    text: TextType;
  };
  deptSticky: {
    fill: FillType;
    text: TextType;
  };
  dept: {
    fill: FillType;
    text: TextType;
  };

  // PoiCategories
  carpark: {
    fill: FillType;
    text: TextType;
    icon: IconType;
    lines: LineType;
  };
  primary: {
    classes: Array<string>;
    fill: FillType;
    text: TextType;
    icon: IconType;
    lines: LineType;
  };
  secondary: {
    classes: Array<string>;
    fill: FillType;
    text: TextType;
    icon: IconType;
    lines: LineType;
  };
  tertiary: {
    classes: Array<string>;
    fill: FillType;
    text: TextType;
    icon: IconType;
    lines: LineType;
  };
  details: {
    classes: Array<string>;
    fill: FillType;
    text: TextType;
    icon: IconType;
    lines: LineType;
  };

  building: {
    fill: FillType;
    text: TextType;
    icon: IconType;
  };
  overlay: {
    icon: IconType;
  };
};
type ColorType = AreaColorType & LineColorType & TextColorType;

export type ColorSettings = {
  underground: AreaColorType;
  outdoor: ColorType;
  garden: AreaColorType;
  water: ColorType;
  carpark: ColorType;
  marking: ColorType;
  kerb: ColorType;

  building: AreaColorType & TextColorType;
  floor: AreaColorType;
  wall: AreaColorType;
  wing: AreaColorType & TextColorType;
  deptSticky: AreaColorType & TextColorType;
  dept: AreaColorType & TextColorType;
  cover: AreaColorType & TextColorType;

  food: ColorType;
  retail: ColorType;
  transport: ColorType;
  facilities: ColorType;
  fun: ColorType;
  emergency: ColorType;
  fire: ColorType;
  accommodation: ColorType;
  room: ColorType;
  hole: ColorType;

  plant: ColorType;
  furniture: ColorType;
  stairs: ColorType;
  infrastructure_travelator: ColorType;
  infrastructure_railing: ColorType;
  transport_skytrain: ColorType;

  default: ColorType;

  details: ColorType;
  tertiary: ColorType;
  secondary: ColorType;
  primary: ColorType;
  waterAndGrass: ColorType;
  texts: ColorType;
};

const colorSettings: ColorSettings = {
  underground: {
    fill: '#B2B2B2',
    outline: '#B2B2B2',
    fill3d: '#B2B2B2'
  },
  outdoor: {
    fill: '#F1F1F1',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#B2B2B2',
    fill3d: '#F1F1F1'
  },
  carpark: {
    fill: '#DDDDDD',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#B2B2B2',
    fill3d: '#DDDDDD'
  },
  marking: {
    fill: '#DDDDDD',
    outline: '#DDDDDD',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#DDDDDD',
    fill3d: '#DDDDDD'
  },
  kerb: {
    fill: '#DDDDDD',
    outline: '#DDDDDD',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#DDDDDD',
    fill3d: '#DDDDDD'
  },
  garden: {
    fill: '#A6BFA5',
    outline: '#B2B2B2'
  },
  water: {
    fill: '#AFD1DB',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#AFD1DB '
  },
  building: {
    fill: '#5a7394',
    outline: '#5a7394',
    text: '#FFFFFF',
    halo: '#FFFFFF'
  },
  floor: {
    fill: '#939BAE',
    outline: '#939BAE'
  },
  wall: {
    fill: '#919191',
    outline: '#B2B2B2',
    fill3d: '#919191'
  },
  wing: {
    fill: '#5a7394',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    fill3d: '#5F6E86'
  },
  deptSticky: {
    fill: '#5a7394',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    fill3d: '#5F6E86'
  },
  dept: {
    fill: '#5a7394',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    fill3d: '#5F6E86'
  },
  cover: {
    fill: '#5a7394',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    fill3d: '#5F6E86'
  },
  retail: {
    fill: '#394E75',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  food: {
    fill: '#976434',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  facilities: {
    fill: '#212436',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  transport: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  fun: {
    fill: '#2f334c',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  emergency: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  fire: {
    fill: '#2f334c',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  accommodation: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  room: {
    fill: '#2f334c',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  hole: {
    fill: '#0a0b10',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#313446',
    lines: '#313446'
  },
  transport_skytrain: {
    fill: '#FFFFFF',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#f6f6f6'
  },
  plant: {
    fill: '#A6BFA5',
    outline: '#273f2b',
    lines: '#273f2b'
  },
  furniture: {
    fill: '#FFFFFF',
    outline: '#B2B2B2',
    lines: '#FFFFFF'
  },
  stairs: {
    fill: '#FFFFFF',
    outline: '#B2B2B2',
    lines: '#FFFFFF'
  },
  infrastructure_travelator: {
    fill: '#FFFFFF',
    outline: '#B2B2B2',
    lines: '#FFFFFF'
  },
  infrastructure_railing: {
    fill: '#FFFFFF',
    outline: '#B2B2B2',
    lines: '#FFFFFF'
  },
  default: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#333',
    lines: '#333'
  },
  details: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#1d202f'
  },
  tertiary: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#5F6E86'
  },
  secondary: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#5F6E86'
  },
  primary: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#5F6E86'
  },
  waterAndGrass: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#5F6E86'
  },
  texts: {
    fill: '#5F6E86',
    outline: '#B2B2B2',
    text: '#FFFFFF',
    halo: '#FFFFFF',
    lines: '#5F6E86'
  },
};
// halo is lighter than the fill color, because mapbox lightens extrusions

const simpleSettings: IndoorSettings = {
  underground: {
    fill: {
      start: 1,
      end: 24,
      opacity: 0.2,
    },
  },
  building: {
    fill: {
      start: 14,
      end: 24,
      opacity: 0.01,
    },
    icon: {
      start: 1,
      end: 14,
    },
    text: {
      start: 10,
      end: 14,
      sizeMin: 6,
      sizeMax: 12,
    },
  },
  outdoor: {
    fill: {
      start: 14,
      end: 24,
      opacity3d: 0.5,
      pattern: '',
    },
    text: {
      start: 14,
      end: 24,
      sizeMin: 6,
      sizeMax: 14,
    },
    icon: {
      start: 14,
      end: 24,
    },
    lines: {
      start: 14,
      end: 24,
    },
  },
  garden: {
    fill: {
      start: 14,
      end: 24,
    },
  },
  water: {
    fill: {
      start: 14,
      end: 24,
    },
  },
  carpark: {
    fill: {
      start: 14,
      end: 24,
      opacity3d: 0,
      height3d: 0,
    },
    text: {
      start: 16,
      end: 24,
      sizeMin: 10,
      sizeMax: 16,
    },
    icon: {
      start: 16,
    },
    lines: { start: 18, end: 24 },
  },
  marking: {
    fill: {
      start: 16,
      end: 24,
    },
    lines: {
      start: 16,
    },
  },
  kerb: {
    fill: {
      start: 16,
      end: 24,
    },
    lines: {
      start: 16,
    },
  },

  floor: {
    fill: {
      start: 14,
      end: 24,
    },
  },
  wall: {
    fill: {
      start: 16,
      end: 24,
      opacity3d: 0.9,
      height3d: 0.5,
    },
  },

  wing: {
    fill: {
      start: 14.5,
      end: 16.5,
      opacity3d: 1,
      height3d: 5,
    },
    text: {
      start: 14.5,
      end: 16.5,
      sizeMin: 6,
      sizeMax: 14,
    },
  },
  deptSticky: {
    fill: {
      start: 16,
      end: 18,
      opacity3d: 1,
      height3d: 1,
    },
    text: {
      start: 16.0,
      end: 17.5,
      sizeMin: 6,
      sizeMax: 14,
    },
  },
  dept: {
    fill: {
      start: 16,
      end: 18,
      opacity3d: 1,
      height3d: 1,
    },
    text: {
      start: 16.0,
      end: 18,
      sizeMin: 6,
      sizeMax: 14,
    },
  },
  cover: {
    fill: {
      start: 16.0,
      end: 24,
      opacity3d: 1,
      height3d: 1,
    },
    text: {
      start: 18.0,
      end: 24,
      sizeMin: 6,
      sizeMax: 14,
    },
  },

  overlay: {
    icon: {
      start: 14,
      end: 24,
    },
  },

  primary: {
    classes: primaryPoiClasses,
    fill: { start: 14, end: 24, opacity3d: 1, height3d: 0.75 },
    icon: { start: 17.5, end: 24 }, // was 16.5
    text: { start: 14, end: 24, sizeMin: 8, sizeMax: 13 }, // was 18
    lines: { start: 18, end: 24 },
  },
  secondary: {
    classes: secondaryPoiClasses,
    fill: { start: 14, end: 24, opacity3d: 1, height3d: 0.75 },
    icon: { start: 17.5, end: 24 },
    text: { start: 14, end: 24, sizeMin: 8, sizeMax: 13 },
    lines: { start: 18, end: 24 },
  },
  tertiary: {
    classes: tertiaryPoiClasses,
    fill: { start: 14, end: 24, opacity3d: 1, height3d: 0.75 },
    icon: { start: 18.5, end: 24 },
    text: { start: 14, end: 24, sizeMin: 8, sizeMax: 13 },
    lines: { start: 18, end: 24 },
  },
  details: {
    classes: detailPoiClasses,
    fill: { start: 18, end: 24, opacity3d: 0.9, height3d: 0.25 },
    lines: { start: 18, end: 24 },
    icon: { start: 18, end: 24 },
    text: { start: 18, end: 24 },
  },
  texts: {
    fill: { start: 16, end: 24 },
    text: { start: 18, end: 24 },
  },

  waterAndGrass: {
    fill: { start: 16, end: 24 },
    lines: { start: 18, end: 24 },
  },
};

export function getIndoorLightTheme() {
  return generateIndoorTheme(simpleSettings, colorSettings);
}
export function getIndoorDarkTheme() {
  const floor = '#939BAE';
  const wall = '#919191';
  const buildingFill = '#5a7394';

  const textColor = '#ffffff';
  const textHalo = '#121212';
  const food3d = '#976434'; // Correct
  const retail3d = '#394E75'; // Correct
  const default3d = '#5F6E86';
  const other3d = default3d; // Pharmacy

  const dept3d = default3d;
  const deptFill = buildingFill;

  const colorSettingsDark: ColorSettings = {
    underground: {
      fill: '#34435c',
      outline: '#34435c',
      fill3d: '#34435c',
    },
    outdoor: {
      fill: '#1d2025',
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: '#777777',
      fill3d: '#1d2025',
    },
    carpark: {
      fill: '#4d4d4d',
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: '#777777',
      fill3d: '#4d4d4d',
    },
    marking: {
      fill: '#999999',
      outline: '#999999',
      text: textColor,
      halo: textHalo,
      lines: '#999999',
      fill3d: '#999999',
    },
    kerb: {
      fill: '#999999',
      outline: '#999999',
      text: textColor,
      halo: textHalo,
      lines: '#999999',
      fill3d: '#999999',
    },
    garden: {
      fill: '#1b2c1e',
      outline: '#131416',
    },
    water: {
      fill: '#294456',
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: '#294456 ',
    },

    building: {
      fill: buildingFill,
      outline: buildingFill,
      text: textColor,
      halo: textHalo,
    },
    floor: {
      fill: floor,
      outline: floor,
    },
    wall: {
      fill: wall,
      outline: '#121212',
      fill3d: wall,
    },
    wing: {
      fill: deptFill,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      fill3d: dept3d,
    },
    deptSticky: {
      fill: deptFill,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      fill3d: dept3d,
    },
    dept: {
      fill: deptFill,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      fill3d: dept3d,
    },
    cover: {
      fill: deptFill,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      fill3d: dept3d,
    },

    retail: {
      fill: retail3d, // 15%
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    food: {
      fill: food3d, // 20%
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    facilities: {
      fill: '#212436',
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    transport: {
      fill: other3d,
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    fun: {
      fill: '#2f334c',
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    emergency: {
      fill: other3d,
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    fire: {
      fill: '#2f334c',
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    accommodation: {
      fill: other3d,
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    room: {
      fill: '#2f334c',
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },
    hole: {
      fill: '#0a0b10',
      outline: '#131416',
      text: textColor,
      halo: '#313446',
      lines: '#313446',
    },

    transport_skytrain: {
      fill: '#121212',
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: '#f6f6f6',
    },
    plant: { fill: '#1b2c1e', outline: '#273f2b', lines: '#273f2b' },
    furniture: { fill: '#121212', outline: '#131416', lines: '#121212' },
    stairs: { fill: '#121212', outline: '#131416', lines: '#121212' },
    infrastructure_travelator: { fill: '#121212', outline: '#131416', lines: '#121212' },
    infrastructure_railing: { fill: '#121212', outline: '#131416', lines: '#121212' },

    default: { fill: default3d, outline: '#131416', text: textColor, halo: '#333', lines: '#333' },

    details: {
      fill: default3d,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: '#1d202f',
    },
    tertiary: {
      fill: default3d,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: default3d,
    },
    secondary: {
      fill: default3d,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: default3d,
    },
    primary: {
      fill: default3d,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: default3d,
    },
    waterAndGrass: {
      fill: default3d,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: default3d,
    },
    texts: {
      fill: default3d,
      outline: '#131416',
      text: textColor,
      halo: textHalo,
      lines: default3d,
    },
  };
  return generateIndoorTheme(simpleSettings, colorSettingsDark);
}
