import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import 'intl';
import 'intl/locale-data/jsonp/en';
import { registerRootComponent } from 'expo';
import * as Sentry from './src/components/sentry/sentry';
import App from './App';
import podExports from './pod-exports.json';

if (!__DEV__) {
  Sentry.init({
    dsn: podExports.sentryDsn,
  });
}

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
