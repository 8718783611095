import React, { FC, useEffect, useMemo, useState } from 'react';
import { Keyboard, Platform, useWindowDimensions, View, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useKeyboard } from '@react-native-community/hooks';
import KeyboardAwareScrollView from '../keyboard-aware-scrollview/KeyboardAwareScrollView';
import SummaryHeader, { SummaryHeaderProps } from '../SummaryHeader';
import { GRADIENT_HEADER_BASE_HEIGHT } from '../DrawerMenuContent';

interface ScrollablePageProps {
  summary?: SummaryHeaderProps;
  footerContent?: JSX.Element;
  children?: React.ReactNode | React.ReactNode[];
}

const ScrollablePage: FC<ScrollablePageProps> = ({ summary, children, footerContent }) => {
  const { colors } = useTheme();
  const insets = useSafeAreaInsets();
  const { width, height } = useWindowDimensions();
  const isMobile = width < 450; // TODO: Better way to figure out of the device is a handheld
  const isSuperWide = width > 800;
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const { keyboardHeight } = useKeyboard();

  const bottomPaddingIOS = useMemo(() => {
    /**
     * 667dp is the window height of iPhone 8. With even smaller devices, we cannot stick the footer above the Keyboard.
     * Consider some other approach if we are about to use the footer anywhere else than in PhoneLogin and ConfirmCode screens.
     * Some devices have extra bottom insets (safe area), removing that when the keyboard is open makes it look the same across devices.
     */
    if (keyboardVisible && height >= 667) {
      return keyboardHeight + 15 - insets.bottom;
    }
    return 15;
  }, [keyboardVisible, height, insets.bottom, keyboardHeight]);

  useEffect(() => {
    const willShowSubscription = Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardVisible(true);
    });
    const willHideSubscription = Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardVisible(false);
    });
    return () => {
      willShowSubscription.remove();
      willHideSubscription.remove();
    };
  }, []);

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      contentContainer: {
        flexGrow: 1,
        width: '100%',
        paddingBottom: insets.bottom,
      },
      footerAtBottomStyle: {
        justifyContent: isMobile ? 'flex-end' : 'flex-start',
        flexGrow: 1,
        paddingBottom: Platform.OS === 'ios' ? bottomPaddingIOS : 15,
        marginTop: 32,
      },
      summaryStyle: {
        backgroundColor: colors.inverseSurface,
        marginBottom: 16,
        paddingLeft: isSuperWide ? 42 + 16 : 16,
        paddingRight: 16,
        minHeight: GRADIENT_HEADER_BASE_HEIGHT + insets.top,
        paddingTop: insets.top,
        paddingBottom: 16, // So that the icon doesn't overflow from the container
      },
      contentStyle: {
        flexGrow: 0,
        maxWidth: 450,
        marginLeft: isSuperWide ? 42 : undefined,
      },

      footerChildrenStyle: {
        flexGrow: 0,
        maxWidth: 450,
        marginLeft: isSuperWide ? 42 : undefined,
      },
    });
  }, [insets, isMobile, isSuperWide, bottomPaddingIOS]);

  return (
    <KeyboardAwareScrollView
      bounces={false}
      style={{
        backgroundColor: colors.background,
      }}
      contentContainerStyle={dynamicStyles.contentContainer}
      keyboardShouldPersistTaps="handled"
      enableOnAndroid
      scrollIndicatorInsets={{ right: 1 }} // Fixes https://github.com/facebook/react-native/issues/26610#issuecomment-539843444
    >
      {/* Header */}
      {summary ? (
        <SummaryHeader
          {...summary}
          topKeepout={insets.top + 56 /* header height */}
          style={dynamicStyles.summaryStyle}
          textColor={colors.inverseOnSurface}
        />
      ) : null}
      {/* Content */}
      <View style={dynamicStyles.contentStyle}>{children}</View>
      {/* Bottom of screen footer */}
      {footerContent ? (
        <View style={dynamicStyles.footerAtBottomStyle}>
          <View style={dynamicStyles.footerChildrenStyle}>{footerContent}</View>
        </View>
      ) : null}
    </KeyboardAwareScrollView>
  );
};

export default ScrollablePage;
