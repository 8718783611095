import React, { useEffect, useState, PropsWithChildren, useContext, useCallback, useRef, useMemo } from 'react';
import { Platform, NativeModules, Linking, EmitterSubscription } from 'react-native';
import type { MixpanelProperties } from 'mixpanel-react-native';
import * as Sentry from '../components/sentry/sentry';
import { BitwardsSDKManager } from './BitwardsSDKManager';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import UnlockingModal, { BitwardsTranslationId, UnlockStage } from './UnlockingModal';
import { BitwardsAPIResult, BitwardsEvent, BitwardsResource } from './BitwardsSDK.types';
import {
  UnlockError,
  selectTroubleshootingDialog,
  sleepAsync,
  userHasAccessToResource,
  synchronizeResourceList,
  verifyBitwardsConnected,
  TroubleshootingDialog,
} from './BitwardsUtils';
import { useHardwareStatus } from '../context/HardwareContext';

type BitwardsContextValue = {
  accessResource: (resourceId: string) => Promise<UnlockError | null>;
  attemptSync: (allowPopup: boolean) => Promise<UnlockError | null>;
  logout: () => Promise<void>;
  knownResources: BitwardsResource[];
  visibleResources: BitwardsResource[];
  loading: boolean;
  error: TroubleshootingDialog | null;
};

const BitwardsContext = React.createContext<BitwardsContextValue | undefined>(undefined);

export type BitwardsCredentials =
  | {
      type: 'basic';
      username: string;
      password: string;
    }
  | {
      type: 'oauth2';
      sessionToken: string;
      refreshToken: string;
      authDomain: string;
    };

export function useThrottledState<T>(initialValue: T, throttleMs: number): [T, (v: T) => void, () => T] {
  const [value, setValue] = useState<T>(initialValue);

  const instantValue = useRef<T>(initialValue);
  const timer = useRef<number | undefined>(undefined);
  const updateRequested = useRef<boolean>(false);

  const setThrottledState = useCallback(
    (newValue: T) => {
      instantValue.current = newValue;

      if (timer.current) {
        updateRequested.current = true;
      } else {
        setValue(instantValue.current);
        timer.current = window.setTimeout(() => {
          if (updateRequested.current) {
            setValue(instantValue.current);
            updateRequested.current = false;
          }
        }, throttleMs);
      }
      timer.current = undefined;
    },
    [throttleMs],
  );

  const getInstantValue = useCallback(() => {
    return instantValue.current;
  }, []);

  useEffect(() => {
    return () => {
      updateRequested.current = false;
      window.clearTimeout(timer.current);
      timer.current = undefined;
    };
  }, []);
  return [value, setThrottledState, getInstantValue];
}

export function BitwardsProvider(
  props: PropsWithChildren<{
    credentials: BitwardsCredentials | undefined;
    translate: (s: BitwardsTranslationId) => string;
    onHelpClicked: () => any;
  }>,
) {
  /* Hooks */
  const {
    isBluetoothEnabled,
    enableBluetooth,
    isLocationServicesEnabled,
    hasBluetoothPermission,
    hasLocationPermission,
  } = useHardwareStatus();

  const mp = useMixpanel();

  /* References */
  const scanStartResult = useRef<BitwardsAPIResult | undefined>(undefined);
  // macAddress is only used for unlocking, resourceId is used for everything else
  const currentUnlockAttempt = useRef<{ macAddress: string | null; resourceId: string } | null>(null);

  /* State */
  const [knownResources, setKnownResources] = useState<BitwardsResource[]>([]);
  const [visibleResources, setVisibleResources, getVisibleResourcesRef] = useThrottledState<BitwardsResource[]>(
    [],
    200,
  );
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [unlockStage, setUnlockStage] = useState<UnlockStage>('IDLE');
  const [initializationError, setInitializationError] = useState<UnlockError | null>(null);
  const [unlockError, setUnlockError] = useState<UnlockError | null>(null);
  const [scanRestarts, setScanRestarts] = useState<number>(0);
  const prevBluetoothState = useRef(false);

  const hasHardwareProblem = useMemo((): UnlockError | null => {
    if (!isBluetoothEnabled && Platform.OS !== 'web') {
      return { source: 'APP', error: 'BLUETOOTH_DISABLED' };
    }

    /* Bitwards requires location services on Android */
    if (Platform.OS === 'android' && isLocationServicesEnabled === false) {
      return { source: 'APP', error: 'ANDROID_LOCATION_DISABLED' };
    }

    return null;
  }, [isBluetoothEnabled, isLocationServicesEnabled]);

  const hasMissingPermissions = useMemo((): UnlockError | null => {
    if (Platform.OS === 'android' && !hasLocationPermission) {
      return { source: 'APP', error: 'ANDROID_LOCATION_NO_PERMISSION' };
    }

    // These permissions are only required on Android 12 or newer.
    if (Platform.OS === 'android' && Platform.Version >= 31 && !hasBluetoothPermission) {
      return { source: 'APP', error: 'ANDROID_BLUETOOTH_NO_PERMISSION' };
    }

    if (Platform.OS === 'ios' && !hasBluetoothPermission) {
      return { source: 'APP', error: 'IOS_BLUETOOTH_NO_PERMISSION' };
    }

    return null;
  }, [hasLocationPermission, hasBluetoothPermission]);

  /* Automatically start / stop scanning */
  useEffect(() => {
    let resourceFoundSubscription: EmitterSubscription;
    let resourceUpdatedSubscription: EmitterSubscription;
    let resourceLostSubscription: EmitterSubscription;
    function resourceUpdated(newResource: BitwardsResource) {
      const curVisible = getVisibleResourcesRef();
      setVisibleResources([...curVisible.filter((res) => res.id !== newResource.id), newResource]);
    }
    function resourceLost(newResource: BitwardsResource) {
      const curVisible = getVisibleResourcesRef();
      setVisibleResources(curVisible.filter((res) => res.id !== newResource.id));
    }

    /* Bitwards connected */
    if (isConnected) {
      /* Hardware enabled */
      if (!hasHardwareProblem) {
        /* No current scan in progress */
        if (scanStartResult.current !== 'SUCCESS') {
          // console.log('Starting scan');

          resourceFoundSubscription = BitwardsSDKManager.addListener(
            BitwardsEvent.RESOURCE_FOUND,
            resourceUpdated,
          ) as EmitterSubscription;
          resourceUpdatedSubscription = BitwardsSDKManager.addListener(
            BitwardsEvent.RESOURCE_UPDATED,
            resourceUpdated,
          ) as EmitterSubscription;
          resourceLostSubscription = BitwardsSDKManager.addListener(
            BitwardsEvent.RESOURCE_LOST,
            resourceLost,
          ) as EmitterSubscription;
          // listenersSet = true;
          BitwardsSDKManager.startBackgroundScan().then((res) => {
            scanStartResult.current = res;
          });
        }
      }
    }
    return () => {
      // console.log('Stopping scan');
      scanStartResult.current = undefined;
      // Don't try to remove listener if they are not first added, otherwise iOS will throw an error (not crash).
      // This always happens after the app has been launched.
      // if (listenersSet) {
      if (resourceFoundSubscription) BitwardsSDKManager.removeListener(resourceFoundSubscription);
      if (resourceUpdatedSubscription) BitwardsSDKManager.removeListener(resourceUpdatedSubscription);
      if (resourceLostSubscription) BitwardsSDKManager.removeListener(resourceFoundSubscription);

      setVisibleResources([]);
      BitwardsSDKManager.stopBackgroundScan().then((res) => {
        if (res !== 'SUCCESS') {
          console.warn('Failed to stop scan, error:', res);
        }
      });
    };
  }, [isConnected, hasHardwareProblem, scanRestarts, getVisibleResourcesRef, setVisibleResources]);

  const restartScan = useCallback(async () => {
    setScanRestarts((prev) => prev + 1);
  }, []);

  const checkInitializedAndReady = useCallback(
    async (allowPopup: boolean, skipSync: boolean, resourceToBeAccessed?: string): Promise<UnlockError | null> => {
      try {
        /* Check we have some credentials */
        if (!props.credentials) {
          return {
            source: 'APP',
            error: 'NO_CREDENTIALS',
          };
        }

        /* Check we have permissions */
        if (hasMissingPermissions) {
          return hasMissingPermissions;
        }

        /* Check bitwards is "connected" */
        const bitwardsConnectError = await verifyBitwardsConnected(props.credentials);
        if (bitwardsConnectError) {
          setIsConnected(false);
          return bitwardsConnectError;
        }
        setIsConnected(true);

        /* Check if sync is needed */
        const resResp = await BitwardsSDKManager.getResourceList();
        if (resResp.status !== 'SUCCESS') {
          return {
            source: 'BITWARDS',
            bitwardsMethod: 'getResourceList',
            bitwardsError: resResp.status,
          };
        }
        let resList = resResp.resourceList;
        if (skipSync || (resourceToBeAccessed && userHasAccessToResource(resourceToBeAccessed, resList))) {
          /* Skipping sync */
        } else {
          const syncRes = await synchronizeResourceList();
          if (
            syncRes.error?.bitwardsError === 'RESOURCE_TOKEN_EXPIRED' ||
            syncRes.error?.bitwardsError === 'ANDROID_ERROR_ACCESS_FORBIDDEN' ||
            syncRes.error?.bitwardsError === 'ANDROID_ERROR_AUTH_TOKEN_EXPIRED' ||
            syncRes.error?.bitwardsError === 'IOS_INTERNAL_ERROR'
          ) {
            console.log('retrying login due to', syncRes);
            // cant't call retryLogin here
            await BitwardsSDKManager.disconnect().then((res) => {
              console.log('bitwards disconnected');
            });
            await sleepAsync(1500); // wait for 1,5 sec before logging in again
            const initError = await checkInitializedAndReady(allowPopup, skipSync, resourceToBeAccessed);
            return initError;
          }
          if (syncRes.error) {
            return syncRes.error;
          }
          resList = syncRes.resources;
          // Added a minor sleep here as after fresh app launch, the lock opening might not work due to USER_RIGHTS_NOT_VALID_FOR_RESOURCE
          // It seems that additional console logs or sleep, somewhere in this function or in attemptUnlock solves the isses.. (WTF?!).
          await sleepAsync(100);
        }
        setKnownResources(resList);
        /* Verify user has access to the resource. */
        if (resourceToBeAccessed && !userHasAccessToResource(resourceToBeAccessed, resList)) {
          return { source: 'APP', error: 'USER_RIGHTS_NOT_VALID_FOR_RESOURCE' };
        }
        /* Check if we have the necessary hardware available */
        if (hasHardwareProblem) {
          return hasHardwareProblem;
        }

        /* All good, no need for trouble shooting dialogs */
        return null;
      } catch (err: any) {
        return {
          source: 'APP',
          error: 'EXCEPTION_WHILE_INTIALIZING',
          exception: err,
        };
      }
    },
    [props.credentials, hasHardwareProblem, hasMissingPermissions],
  );

  const updateInitializationStatus = useCallback(
    async (allowPopup: boolean, skipSync: boolean, resourceToBeAccessed?: string) => {
      setInitializationError(await checkInitializedAndReady(allowPopup, skipSync, resourceToBeAccessed));
    },
    [checkInitializedAndReady],
  );

  const retryLogin = useCallback(async () => {
    await BitwardsSDKManager.disconnect().then((res) => {});
    // setIsConnected(false);
    updateInitializationStatus(false, false);
  }, [updateInitializationStatus]);

  const attemptSync = useCallback(
    async (allowPopup: boolean) => {
      const v = await checkInitializedAndReady(allowPopup, false, undefined);
      setInitializationError(v);
      return v;
    },
    [checkInitializedAndReady],
  );

  const checkDeviceVisible = useCallback(
    async (resourceId: string): Promise<UnlockError | null> => {
      /* First check scan is running */
      if (scanStartResult.current && scanStartResult.current !== 'SUCCESS') {
        return {
          source: 'BITWARDS',
          bitwardsMethod: 'startBackgroundScan',
          bitwardsError: scanStartResult.current,
        };
      }

      /* Then check device is visible */
      for (let attempt = 0; attempt < 10; attempt++) {
        /* Don't sleep before first attempt */
        if (attempt !== 0) {
          // eslint-disable-next-line no-await-in-loop
          await sleepAsync(1000);
        }
        const deviceFound = getVisibleResourcesRef().find((res) => res.id === resourceId);
        if (deviceFound) {
          return null; /* Device found, all good */
        }
        if (attempt === 6) {
          // eslint-disable-next-line no-await-in-loop
          await restartScan();
        }
      }
      /* No device found in 8 attempts */

      /* Check if we are even scanning */
      if (!scanStartResult.current) {
        return {
          source: 'APP',
          error: 'SCAN_NOT_STARTED',
        };
      }
      const devicesSeen = getVisibleResourcesRef().length;
      return {
        source: 'APP',
        error: devicesSeen === 0 ? 'NO_DEVICES_SEEN_DURING_SCAN' : 'SPECIFIC_DEVICE_NOT_SEEN_DURING_SCAN',
      };
    },
    [getVisibleResourcesRef, restartScan],
  );

  const attemptUnlock = useCallback(async (): Promise<UnlockError | null> => {
    if (!currentUnlockAttempt.current?.macAddress) return null; // TODO: Error

    /* Check if we are initialised, and have access rights to the lock */
    setUnlockStage('INITIALIZING');
    const initError = await checkInitializedAndReady(true, false, currentUnlockAttempt.current.resourceId);
    setInitializationError(initError);
    if (initError !== null) {
      setUnlockError(initError);
      return initError;
    }

    setUnlockStage('SCANNING');
    const scanError = await checkDeviceVisible(currentUnlockAttempt.current.resourceId);

    if (scanError !== null) {
      setUnlockError(scanError);
      return scanError;
    }

    /* Seems everything is in order for opening the lock */
    try {
      setUnlockStage('ACCESSING');
      // Need to access with macAddress and not with resourceId due to Bitwards native implementation.
      const result = await BitwardsSDKManager.accessResource(currentUnlockAttempt.current.macAddress);

      if (result !== 'SUCCESS') {
        const e: UnlockError = {
          source: 'BITWARDS',
          bitwardsMethod: 'accessResource',
          bitwardsError: result,
        };
        setUnlockError(e);
        return e;
      }
      setUnlockStage('OPEN');
      /* Lock after 10 seconds */
      setTimeout(() => setUnlockStage((oldStage) => (oldStage === 'OPEN' ? 'IDLE' : oldStage)), 10 * 1000);
      return null;
    } catch (error: unknown) {
      const e: UnlockError = {
        source: 'APP',
        error: 'EXCEPTION_WHILE_ACCESSING_RESOURCE',
        exception: error,
      };
      setUnlockError(e);
      return e;
    }
  }, [checkDeviceVisible, checkInitializedAndReady]);

  /** Mac address in bitwards unlocking deeplinks doesn't contain colons */
  const getValidMacAddress = (macAddress: string): string => {
    const regex: string = '^([0-9A-Fa-f]{2}[:-])';
    if (macAddress.match(regex)) {
      return macAddress;
    }

    const validMacAddress = macAddress.replace(/.(?=(..)+$)/g, '$&:');
    return validMacAddress;
  };

  /**
   * Bicyclehut specific method. We've stored lock id's into to the Pod's space info in cms,
   * but we need to use macAddress to access the resource.
   */
  const getMacAddressForResource = useCallback(
    (resourceId: string): string | undefined => {
      const res = knownResources.find((r) => r.id === resourceId);
      return res?.macAddress;
    },
    [knownResources],
  );

  const accessResource = useCallback(
    async (resourceId: string): Promise<UnlockError | null> => {
      currentUnlockAttempt.current = { resourceId, macAddress: null };
      /* Immediately show that we have started unlocking */
      setUnlockStage('STARTING');
      setUnlockError(null);
      const macAddress = getMacAddressForResource(resourceId);
      // No macAddress likely means that the resource is not known so let's check if the service is initialized
      if (!macAddress) {
        setUnlockStage('INITIALIZING');
        const initError = await checkInitializedAndReady(true, false, resourceId);
        if (initError) {
          setInitializationError(initError);
          setUnlockError(initError);
          return initError;
        }
        const err = { error: 'EXCEPTION_WHILE_ACCESSING_RESOURCE' } as UnlockError;
        setUnlockError(err);
        setInitializationError(err);
        return err;
      }
      const validMacAddress = getValidMacAddress(macAddress);
      if (currentUnlockAttempt.current.macAddress) {
        return null; // TODO: Error, busy
      }
      currentUnlockAttempt.current = { resourceId, macAddress: validMacAddress };
      return attemptUnlock();
    },
    [attemptUnlock, checkInitializedAndReady, getMacAddressForResource],
  );

  /* Monitor force logout stuff */
  useEffect(() => {
    const retryLoginSubscription = BitwardsSDKManager.addListener(
      BitwardsEvent.IOS_FORCE_LOGOUT,
      retryLogin,
    ) as EmitterSubscription;
    const iosUnauthenticatedSubsciption = BitwardsSDKManager.addListener(
      BitwardsEvent.IOS_USER_UNAUTHENTICATED,
      retryLogin,
    ) as EmitterSubscription;
    return () => {
      if (retryLoginSubscription) BitwardsSDKManager.removeListener(retryLoginSubscription);
      if (iosUnauthenticatedSubsciption) BitwardsSDKManager.removeListener(iosUnauthenticatedSubsciption);
    };
  }, [retryLogin]);

  useEffect(() => {
    /* Unregister scan on unmount */
    return () => {
      if (scanStartResult.current === 'SUCCESS') {
        scanStartResult.current = undefined;
        BitwardsSDKManager.stopBackgroundScan().then((res) => {
          if (res !== 'SUCCESS') {
            console.warn('Failed to stop scan, error:', res);
          }
        });
      }
    };
  }, []);

  /* Initialize on startup, quick if there is a url */
  useEffect(() => {
    Linking.getInitialURL().then((val) => {
      if (val) {
        /* If there is an initial url, we do a quicker context initialization */
        updateInitializationStatus(false, true);
      } else {
        updateInitializationStatus(false, false);
      }
    });
    // This is opportunistic and optional and we only do it once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* On iOS, reset visible resources and retry login if bluetooth goes off */
  useEffect(() => {
    if (Platform.OS === 'ios' && isConnected && !isBluetoothEnabled && prevBluetoothState.current === true) {
      setVisibleResources([]);
      retryLogin();
    }
    prevBluetoothState.current = isBluetoothEnabled;
  }, [isBluetoothEnabled, isConnected, retryLogin, setVisibleResources]);

  const errorDialog = useMemo(() => selectTroubleshootingDialog(unlockError), [unlockError]);

  /* Location services monitoring: */
  useEffect(() => {
    if (currentUnlockAttempt.current && errorDialog === 'LOCATION_HW_OFF' && isLocationServicesEnabled) {
      console.log('Looks like location is now on, retrying');
      accessResource(currentUnlockAttempt.current?.resourceId);
    }
  }, [accessResource, attemptUnlock, errorDialog, isLocationServicesEnabled]);

  /*  Bluetooth monitoring: retry if it becomes enabled while we are waiting for it */
  useEffect(() => {
    if (currentUnlockAttempt.current && isBluetoothEnabled) {
      if (unlockStage === 'ENABLING_BLUETOOTH' || errorDialog === 'BLUETOOTH_HW_OFF') {
        accessResource(currentUnlockAttempt.current.resourceId);
      }
    }
  }, [accessResource, attemptUnlock, errorDialog, isBluetoothEnabled, unlockStage]);

  /** Bluetooth permission monitoring */
  useEffect(() => {
    if (currentUnlockAttempt.current && hasBluetoothPermission) {
      if (errorDialog === 'BLUETOOTH_PERMISSION_ANDROID' || errorDialog === 'BLUETOOTH_PERMISSION_IOS') {
        accessResource(currentUnlockAttempt.current.resourceId);
      }
    }
  }, [accessResource, attemptUnlock, currentUnlockAttempt, errorDialog, hasBluetoothPermission]);

  /** Location permission monitoring */
  useEffect(() => {
    if (currentUnlockAttempt.current && hasLocationPermission) {
      if (errorDialog === 'LOCATION_PERMISSION') {
        accessResource(currentUnlockAttempt.current.resourceId);
      }
    }
  }, [accessResource, attemptUnlock, currentUnlockAttempt, errorDialog, hasLocationPermission]);

  const handleOnClose = useCallback(async () => {
    setUnlockError(null);
    setUnlockStage('IDLE');
    currentUnlockAttempt.current = null;
  }, []);

  const handleTryEnableBluetooth = useCallback(async () => {
    mp?.track('Enable bluetooth clicked');
    setUnlockError(null);
    setUnlockStage('ENABLING_BLUETOOTH');
    enableBluetooth().finally(() => {
      /* 
      After completing this attempt, wait for 1s and then
      if we are still enabling bluetooth, re-show this dialog 
      */
      setTimeout(() => {
        setUnlockStage((old) => {
          if (old === 'ENABLING_BLUETOOTH') {
            /* Bluetooth didn't get enabled yet */
            setUnlockError({
              source: 'APP',
              error: 'BLUETOOTH_ENABLE_TIMEOUT',
            });
          }
          return old;
        });
      }, 1000);
    });
  }, [mp, enableBluetooth]);

  /* Analytics */
  useEffect(() => {
    const lock = currentUnlockAttempt.current?.resourceId;
    if (unlockStage === 'OPEN') {
      mp?.track('Unlock successful', { lock });
      mp?.getPeople().increment('Unlocks', 1);
    }
    if (unlockStage === 'ACCESSING' && unlockError) {
      mp?.track('Unlock failed', {
        lock,
        err: unlockError.source === 'BITWARDS' ? unlockError.bitwardsError : unlockError.error,
      });
      mp?.getPeople().increment('Failed unlocks', 1);
      if (NativeModules.RNBikettiCustomSentryModule) {
        const numberOfLines = 100;
        NativeModules.RNBikettiCustomSentryModule.recordCurrentLogcatToSentry(numberOfLines, (_error: any) => {
          Sentry.captureException(new Error(`Failed to unlock resource ${lock}`));
        });
      }
    } else if (unlockError) {
      const evt: MixpanelProperties = {
        lock,
        dialog: selectTroubleshootingDialog(unlockError),
        unlockStage,
        errorSource: unlockError.source,
      };
      if (unlockError.source === 'BITWARDS') {
        evt.bitwardsMethod = unlockError.bitwardsMethod;
        evt.bitwardsError = unlockError.bitwardsError;
      } else {
        evt.error = unlockError.error;
        evt.exception =
          unlockError.error === 'EXCEPTION_WHILE_ACCESSING_RESOURCE' ||
          unlockError.error === 'EXCEPTION_WHILE_INTIALIZING'
            ? `${unlockError.exception}`
            : '';
      }
      mp?.track('Unlock troubleshooting', evt);
    }
  }, [mp, unlockError, unlockStage]);

  const logout = useCallback(async () => {
    // if location permission is not granted biwards init won't work
    // -> cannot logout as service is not connected
    if (isConnected === true) {
      await BitwardsSDKManager.disconnect();
    }
    setIsConnected(false);
    currentUnlockAttempt.current = null;
    setUnlockError(null);
    setUnlockStage('IDLE');
  }, [isConnected]);

  /* Convert error to trouble shooting dialog */
  const value: BitwardsContextValue = useMemo(
    () => ({
      accessResource,
      logout,
      attemptSync,
      knownResources,
      visibleResources,
      loading: unlockStage !== 'IDLE',
      error: unlockError ? selectTroubleshootingDialog(unlockError) : selectTroubleshootingDialog(initializationError),
    }),
    [
      accessResource,
      logout,
      attemptSync,
      knownResources,
      visibleResources,
      unlockStage,
      unlockError,
      initializationError,
    ],
  );

  return (
    <BitwardsContext.Provider value={value}>
      <UnlockingModal
        visible={unlockStage !== 'IDLE'}
        retryUnlock={() => {
          if (currentUnlockAttempt.current) {
            accessResource(currentUnlockAttempt.current.resourceId);
          }
        }}
        unlockStage={unlockStage}
        errorDialog={errorDialog}
        unlockError={unlockError}
        onTryEnablingBluetooth={handleTryEnableBluetooth}
        onClose={handleOnClose}
        t={props.translate}
        onHelpClicked={props.onHelpClicked}
      />
      {props.children}
    </BitwardsContext.Provider>
  );
}

export function useBitwards() {
  const value = useContext(BitwardsContext);
  if (!value) {
    throw new Error('Bitwards hook is not used inside BitwardsProvider');
  }
  return value;
}
