import { Style } from 'maplibre-gl';
import { getIndoorLayers } from './indoor/IndoorLayers';
import { getIndoorLightTheme, getIndoorDarkTheme } from './indoor/IndoorThemeGenerator';
import { getBaseLayers } from './osm/OSMLibreLayers';
import { getLightTheme, getDarkTheme } from './osm/OutdoorThemeGenerator';
import podExports from '../../../../../../pod-exports.json';

export function getStyle(theme: 'dark' | 'light'): Style {
  const outdoorStyle = theme === 'light' ? getLightTheme() : getDarkTheme();
  const indoorStyle = theme === 'light' ? getIndoorLightTheme() : getIndoorDarkTheme();
  const outdoorLayers = getBaseLayers(outdoorStyle);
  const indoorLayers = getIndoorLayers(indoorStyle);
  return {
    version: 8,
    name: 'Biketti-Default-v2',
    metadata: {},
    sources: {
      openmaptiles: {
        type: 'vector',
        url: 'https://api.maptiler.com/tiles/v3/tiles.json?key=hpGpLh6crBZmLKbxDPwq',
      },
      biketti_base_tiles: {
        type: 'vector',
        tiles: [podExports.martinHost + '/biketti_base_tiles/{z}/{x}/{y}'],
        minzoom: 1,
        maxzoom: 19,
      },
      biketti_floor_tiles: {
        type: 'vector',
        tiles: [podExports.martinHost + '/biketti_floor_tiles_{f}/{z}/{x}/{y}'],
        minzoom: 12,
        maxzoom: 19,
      },
    },
    // TODO: Add sprites
    // sprite: 'https://mapdata.eu.biketti.net/style/sprites_dark?access_token=' + podExports.mapApiKey, // "https://maputnik.github.io/osm-liberty/sprites/osm-liberty",
    glyphs: 'https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=hpGpLh6crBZmLKbxDPwq',
    transition: {
      delay: 0,
      duration: 600,
    },
    layers: outdoorLayers.concat(indoorLayers),
  };
}
