import type maplibregl from 'maplibre-gl';
import type { CameraOptions } from 'maplibre-gl';

export type MoveCompletion = (wasCompleted: boolean, camera: maplibregl.CameraOptions) => void | null;

type MoveToFlyOptions = {
  movementType: 'fly';
  options: maplibregl.FlyToOptions & { minZoom?: number };
  allowInterrupting: boolean;
  replaceId?: string;
};
type MoveToEaseOptions = {
  movementType: 'ease';
  options: maplibregl.EaseToOptions & { minZoom?: number };
  allowInterrupting: boolean;
  replaceId?: string;
};

type MoveToJumpOptions = {
  movementType: 'jump';
  options: maplibregl.CameraOptions & { minZoom?: number };
  allowInterrupting: boolean;
  replaceId?: string;
};
type MoveToNonFitOptions = MoveToFlyOptions | MoveToEaseOptions | MoveToJumpOptions;

type MoveToFitOptions = MoveToNonFitOptions & {
  options: maplibregl.CameraForBoundsOptions & { minZoom?: number };
  fit: [number, number][];
};
export type MoveToOptions = MoveToNonFitOptions | MoveToFitOptions;

export type MapWithMovementControls = maplibregl.Map & {
  biketti: {
    moveTo: (m: MoveToOptions, cb: MoveCompletion | null) => void;
  };
};

export function initMovementHandling(
  vars: {
    enclosingCircleJS: string;
    onStoppedCamera: ((c: CameraOptions) => void) | null;
    warn: typeof window.console.warn;
  },
  basicMap: maplibregl.Map,
  lib: typeof maplibregl,
  win: Window,
) {
  /* eslint-disable no-eval */
  const enclosingCircle = eval(vars.enclosingCircleJS);
  const map = basicMap as MapWithMovementControls;

  const movementQueue: [MoveToOptions, MoveCompletion | null][] = [];
  let interactionsDisabledTimestamp: number | undefined;
  function _disableInteractions() {
    // console.log("MoveTo: Disabling interactions");
    map.dragPan.disable();
    map.boxZoom.disable();
    map.dragPan.disable();
    map.dragRotate.disable();
    map.keyboard.disable();
    map.doubleClickZoom.disable();
    map.scrollZoom.disable();
    console.assert(interactionsDisabledTimestamp === undefined);
    interactionsDisabledTimestamp = Date.now();
  }

  function _enableInteractions(opts: MoveToOptions) {
    map.dragPan.enable();
    map.boxZoom.enable();
    map.dragPan.enable();
    map.dragRotate.enable();
    map.keyboard.enable();
    map.doubleClickZoom.enable();
    map.scrollZoom.enable();

    console.assert(interactionsDisabledTimestamp !== undefined);
    const durationMs = Date.now() - (interactionsDisabledTimestamp as number);
    if (durationMs > 1000) {
      vars.warn(`MoveTo: Animation took too long, ${durationMs}ms. Options provided were many ${opts.options}`);
    }
    interactionsDisabledTimestamp = undefined;
  }

  function reportMoveToEnd(opts: MoveToOptions, completion: MoveCompletion | null, e: Event): void {
    const c = map.getCenter();
    const cam: maplibregl.CameraOptions = {
      center: c,
      pitch: map.getPitch(),
      bearing: map.getBearing(),
      zoom: map.getZoom(),
    };

    /* Investigate if we were interrupted */
    let wasInterrupted: null | string = null;
    if (opts.options.center) {
      const llArr = opts.options.center as [number, number];
      const llObj = opts.options.center as maplibregl.LngLat;
      let d;
      if (llObj.lat !== undefined) {
        d = c.distanceTo(llObj);
      } else {
        d = c.distanceTo({ lng: llArr[0], lat: llArr[1] } as maplibregl.LngLat);
      }

      if ((cam.zoom! < 14 && d > 0.1) || (cam.zoom! >= 14 && d > 0.001)) {
        wasInterrupted = `distance ${d}: Target ${llObj}, actual ${c}`;
      }
    }
    if (opts.options.pitch !== undefined && Math.round(cam.pitch!) !== Math.round(opts.options.pitch)) {
      wasInterrupted = `pitch: Target ${opts.options.pitch}, actual ${cam.pitch}`;
    }
    if (opts.options.bearing !== undefined && Math.round(cam.bearing! * 10) !== Math.round(opts.options.bearing * 10)) {
      wasInterrupted = `bearing: Target ${opts.options.bearing}, actual ${cam.bearing}`;
    }
    if (opts.options.zoom !== undefined && Math.round(cam.zoom! * 100) !== Math.round(opts.options.zoom * 100)) {
      wasInterrupted = `zoom: Target ${opts.options.zoom}, actual ${cam.zoom}`;
    }
    if (!opts.allowInterrupting) {
      _enableInteractions(opts);
      if (wasInterrupted) {
        /* 
          So in some cases the window is blurred or mapbox has a bug which has 
          caused an interruption.

          Since we this is supposed to be uninterruptable, and it
          just got interrupted, we just jump to the end value.
          */
        map.jumpTo(opts.options);
        wasInterrupted = null;
      }
    }

    if (completion) {
      try {
        completion(wasInterrupted != null, cam);
      } catch (err) {
        vars.warn('MoveTo: Completion threw error');
      }
    }

    nextFromQueue();
  }

  function executeMoveTo(opts: MoveToOptions, completion: MoveCompletion | null) {
    if (map.isMoving()) {
      map.stop();
    }

    if ((opts as MoveToFitOptions).fit) {
      const points = (opts as MoveToFitOptions).fit.map((e) => map.project(e));
      const circle: { x: number; y: number; r: number } = enclosingCircle(points);
      const topLeft = map.unproject([circle.x - circle.r, circle.y - circle.r]);
      const bottomRight = map.unproject([circle.x + circle.r, circle.y + circle.r]);
      const camera = map.cameraForBounds([topLeft, bottomRight], { padding: { top: 0, left: 0, right: 0, bottom: 0 } });
      if (camera) {
        // TODO: can't really figure out a better way to do it, so disabling eslint for now.
        /* eslint-disable no-param-reassign */
        opts.options.zoom = Math.max(camera.zoom, (opts as MoveToFitOptions).options.minZoom ?? 0);
        /* eslint-disable no-param-reassign */
        opts.options.center = camera.center;
        // console.log("Fit center", opts.options.center);
      } else {
        console.warn('Unable to get camera for bounds');
        /* eslint-disable no-param-reassign */
        opts.options.center = map.unproject([circle.x, circle.y]);
      }
    }
    if (opts.options.minZoom && opts.options.zoom === undefined) {
      /* eslint-disable no-param-reassign */
      opts.options.zoom = Math.max(map.getZoom(), opts.options.minZoom);
    }

    if (opts.movementType === 'fly' && opts.options.center) {
      const mapCenter = map.getCenter();
      const targetCenter = lib.LngLat.convert(opts.options.center);
      const distanceToTargetM = mapCenter.distanceTo(targetCenter);
      if (distanceToTargetM <= 1000) {
        // console.log("Converting fly to ease");
        (opts as any).movementType = 'ease';
      }
    }

    // vars.log("MoveTo: moveend ON");
    const w = (evt: Event) => {
      map.off('moveend', w);
      reportMoveToEnd(opts, completion, evt);
    };
    map.on('moveend', w);

    if (!opts.allowInterrupting) {
      _disableInteractions();
    }

    // console.log("MoveTo: Start", opts);
    if (opts.movementType === 'jump') {
      map.jumpTo(opts.options);
    } else if (opts.movementType === 'fly') {
      map.flyTo(opts.options as maplibregl.FlyToOptions);
    } else if (opts.movementType === 'ease') {
      map.easeTo(opts.options as maplibregl.EaseToOptions);
    }
  }

  function nextFromQueue() {
    movementQueue.shift();
    if (movementQueue.length > 0) {
      executeMoveTo(movementQueue[0][0], movementQueue[0][1]);
    }
  }

  function enqueue(opts: MoveToOptions, completion: MoveCompletion | null) {
    const lastMovement = movementQueue.length > 0 ? movementQueue[movementQueue.length - 1] : [undefined, undefined];
    if (opts.replaceId && lastMovement[0]?.replaceId === opts.replaceId) {
      // console.log('Replacing', lastMovement[0], 'with', opts);
      /* Replace last movememnt, even if ongoing, with this one. */
      movementQueue[movementQueue.length - 1] = [
        opts,
        (wasCompleted: boolean, camera: maplibregl.CameraOptions) => {
          if (lastMovement[1]) lastMovement[1](wasCompleted, camera);
          if (completion) completion(wasCompleted, camera);
        },
      ];
    } else {
      movementQueue.push([opts, completion]);
    }

    if (movementQueue.length === 1) {
      executeMoveTo(movementQueue[0][0], movementQueue[0][1]);
    } else if (movementQueue.length > 3) {
      vars.warn(`MoveTo: Many animations (${movementQueue.length}) queued up`);
    }
  }

  const moveTo = function moveTo(opts: MoveToOptions, completion: MoveCompletion | null) {
    enqueue(opts, completion);
  };

  if (!map.biketti) {
    map.biketti = { moveTo };
  } else {
    map.biketti.moveTo = moveTo;
  }
  if (vars.onStoppedCamera !== null) {
    map.on('moveend', () => {
      if (vars.onStoppedCamera !== null) {
        vars.onStoppedCamera({
          center: map.getCenter(),
          bearing: map.getBearing(),
          pitch: map.getPitch(),
          zoom: map.getZoom(),
        });
      }
    });
  }
}
export default initMovementHandling;
