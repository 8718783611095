import React, { useCallback, useMemo } from 'react';
import { Appbar, IconButton, useTheme } from 'react-native-paper';
import { StackHeaderProps, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import { useWindowDimensions, View } from 'react-native';
import { DrawerActions } from '@react-navigation/core';
import { SafeAreaView } from 'react-native-safe-area-context';
import { uiIconSourceGenerator } from './SimpleListIcon';
import Typography from './Typography';

function ButtonStackNavigatorHeader(props: StackHeaderProps) {
  const { navigation, back } = props;
  const { width } = useWindowDimensions();
  const showBackButton = !!back;
  const showMenuButton = !showBackButton && width <= 600;
  const { colors } = useTheme();

  if (showBackButton) {
    return (
      <SafeAreaView>
        <View style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
          <IconButton
            icon={uiIconSourceGenerator({ name: 'arrow-left', size: 21, color: colors.inverseOnSurface })}
            onPress={navigation.goBack}
            style={{ margin: 8, backgroundColor: colors.inverseSurface }}
          />
        </View>
      </SafeAreaView>
    );
  }
  if (showMenuButton) {
    return (
      <SafeAreaView>
        <View style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
          <IconButton
            icon={uiIconSourceGenerator({ name: 'menu', size: 21, color: colors.inverseOnSurface })}
            onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            style={{ margin: 8 }}
          />
        </View>
      </SafeAreaView>
    );
  }
  return null;
}

function AppbarStackNavigatorHeader(props: StackHeaderProps) {
  const { navigation, options, back } = props;
  const { width } = useWindowDimensions();
  const { colors } = useTheme();

  const renderAction = useCallback(() => {
    const showBackButton = !!back;
    const showMenuButton = !showBackButton && width <= 600;

    if (options.headerLeft) {
      return <View>{options.headerLeft({})}</View>;
    }
    if (showBackButton) {
      return (
        <Appbar.Action
          animated={false}
          accessibilityRole={'button'}
          icon={uiIconSourceGenerator({ name: 'arrow-left', size: 16, color: colors.inverseOnSurface })}
          onPress={navigation.goBack}
        />
      );
    }
    if (showMenuButton) {
      return (
        <Appbar.Action
          animated={false}
          accessibilityRole={'button'}
          icon={uiIconSourceGenerator({ name: 'menu', size: 16, color: colors.inverseOnSurface })}
          onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        />
      );
    }
    return null;
  }, [back, navigation, options, width]);

  const titleComponent = useMemo(() => {
    return (
      <Typography variant="h5" color={colors.inverseOnSurface}>
        {options.title}
      </Typography>
    );
  }, [options.title, colors.inverseOnSurface]);

  return (
    <Appbar.Header style={{ elevation: 0, backgroundColor: colors.inverseSurface }} accessibilityRole={'header'}>
      {/* Custom HeaderLeft or Menu or back button button */}
      {renderAction()}
      <Appbar.Content title={titleComponent} subtitle={(options as any).subtitle} />
      {options.headerRight && <View>{options.headerRight({})}</View>}
    </Appbar.Header>
  );
}

export const AppbarScreenOptions: StackNavigationOptions = {
  header: (props: StackHeaderProps) => <AppbarStackNavigatorHeader {...props} />,
  animationEnabled: true,
  headerTransparent: false,
  presentation: 'card',
  headerMode: 'screen',
  ...TransitionPresets.SlideFromRightIOS, // Force iOS styled animation
};

export const DefaultScreenOptions: StackNavigationOptions = {
  header: (props: StackHeaderProps) => <ButtonStackNavigatorHeader {...props} />,
  animationEnabled: true,
  headerTransparent: true,
  presentation: 'card',
  headerMode: 'screen',
  ...TransitionPresets.SlideFromRightIOS, // Force iOS styled animation
};

export const DefaultModalOptions: StackNavigationOptions = {
  headerShown: false,
  animationEnabled: true,
  presentation: 'transparentModal',
  ...TransitionPresets.ModalFadeTransition,
};
