import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import Constants from 'expo-constants';
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack';
import { useTheme } from 'react-native-paper';
import { StatusBar } from 'expo-status-bar';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/core';
import { LoginScreenRouteParams, PhoneLoginScreen } from '../screens/Onboarding/PhoneLogin';
import { ConfirmCode, ConfirmationCodeRouteParams } from '../screens/Onboarding/ConfirmCode';
import DrawerNavigator from './DrawerNavigator';
import { screenNames } from './screenNames';
import { AuthState, useAuth } from '../context/AuthContext';
import CompleteRegistration from '../screens/Onboarding/CompleteRegistration';
import { useIntroContext } from '../context/IntroContext';
import Intro from '../screens/Onboarding/Intro';
import { BitwardsCredentials, BitwardsProvider } from '../bitwards/BitwardsContext';
import OnlinePage, { OnlinePageRouteParams } from '../screens/OnlinePage';
import { useI18n } from '../context/I18nContext';
import { ActivePaymentProvider } from '../payment/MapViewActivePaymentContext';
import { MapViewFilterProvider } from '../context/MapViewFilterContext';
import { MapViewBannerProvider } from '../context/MapViewBannerContext';
import { EnterInvitationCodeRouteParams, EnterInvitationCodeScreen } from '../screens/EnterInvitationCodeScreen';
import WelcomeWithoutInvite from '../screens/Onboarding/WelcomeWithoutInvite';
import WelcomeWithCredits from '../screens/Onboarding/WelcomeWithInvite';
import ForceUpdate from '../screens/ForceUpdate/ForceUpdate';
import { AppDrawerNavigationProp, DrawerRouteParams } from '../components/DrawerMenuContent';
import { AppbarScreenOptions } from '../components/NavigatorAppbar';
import { UiIcon } from '../components/SimpleListIcon';

const { bitwardSuperUsername, bitwardSuperUserPassword } = require('../../pod-exports.json');

type IntroStackRouteParams = {
  [screenNames.Intro]: undefined;
};
type AuthenticatedStackRouteParams = {
  [screenNames.Home]: DrawerRouteParams;
};

export type NotAuthenticatedStackRouteParams = {
  [screenNames.Login]: LoginScreenRouteParams;
  [screenNames.EnterInvitationCode]: EnterInvitationCodeRouteParams;
  [screenNames.ToS]: undefined | OnlinePageRouteParams;
  [screenNames.PP]: undefined | OnlinePageRouteParams;
  [screenNames.ConfirmCode]: ConfirmationCodeRouteParams;
};
type CompleteRegistrationStackRouteParams = {
  [screenNames.CompleteRegistration]: undefined;
  [screenNames.WelcomeWithoutInvite]: undefined;
  [screenNames.WelcomeWithInvite]: undefined;
};

export type RootStackRouteParams = IntroStackRouteParams &
  AuthenticatedStackRouteParams &
  NotAuthenticatedStackRouteParams &
  CompleteRegistrationStackRouteParams;

export type RootScreenProps = StackScreenProps<RootStackRouteParams>;

const Stack = createStackNavigator<RootStackRouteParams>();

export function RootStack() {
  const { I18n } = useI18n();
  const { colors, fonts } = useTheme();
  const { state } = useAuth();
  const { state: introState } = useIntroContext();
  const { top } = useSafeAreaInsets();
  const navigation = useNavigation<AppDrawerNavigationProp>();

  const renderRoute = () => {
    if (introState) {
      return (
        <Stack.Screen name={screenNames.Intro} component={Intro} options={{ ...AppbarScreenOptions, title: '' }} />
      );
    }
    switch (state) {
      case AuthState.GUEST_LOGIN: {
        return (
          <>
            <Stack.Screen
              options={{
                header: () => null,
              }}
              name={screenNames.Home}
              component={DrawerNavigator}
            />
            <Stack.Screen
              name={screenNames.Login}
              component={PhoneLoginScreen}
              options={{
                ...AppbarScreenOptions,
                headerTitle: '',
                /* No menu */
                headerLeft: () => null,
              }}
            />
            <Stack.Screen
              options={{
                headerTitle: I18n.t('screenNames.verification'),
              }}
              name={screenNames.ConfirmCode}
              component={ConfirmCode}
            />
            <Stack.Screen name={screenNames.EnterInvitationCode} component={EnterInvitationCodeScreen} />
          </>
        );
      }
      case AuthState.AUTHENTICATED: {
        return (
          <Stack.Screen
            options={{
              header: () => null,
            }}
            name={screenNames.Home}
            component={DrawerNavigator}
          />
        );
      }
      case AuthState.NOT_AUTHENTICATED: {
        return (
          <>
            <Stack.Screen
              name={screenNames.Login}
              component={PhoneLoginScreen}
              options={{
                ...AppbarScreenOptions,
                headerTitle: '',
                /* No menu */
                headerLeft: () => null,
              }}
            />
            <Stack.Screen name={screenNames.EnterInvitationCode} component={EnterInvitationCodeScreen} />
            <Stack.Screen
              name={screenNames.ToS}
              component={OnlinePage}
              options={{
                ...AppbarScreenOptions,
                title: I18n.t('help.tos.title'),
              }}
              initialParams={{
                url: I18n.t('help.tos.url'),
              }}
            />
            <Stack.Screen
              name={screenNames.PP}
              component={OnlinePage}
              options={{
                ...AppbarScreenOptions,
                title: I18n.t('help.pp.title'),
              }}
              initialParams={{
                url: I18n.t('help.pp.url'),
              }}
            />
            <Stack.Screen
              options={{
                headerTitle: I18n.t('screenNames.verification'),
              }}
              name={screenNames.ConfirmCode}
              component={ConfirmCode}
            />
          </>
        );
      }
      case AuthState.NEED_COMPLETE_REGISTRATION: {
        return (
          <>
            <Stack.Screen
              options={{
                title: '',
                /* No menu */
                headerLeft: () => null,
              }}
              name={screenNames.CompleteRegistration}
              component={CompleteRegistration}
            />

            <Stack.Screen
              name={screenNames.WelcomeWithoutInvite}
              component={WelcomeWithoutInvite}
              options={{
                title: '',
                headerLeft: () => null,
              }}
            />
            <Stack.Screen
              name={screenNames.WelcomeWithInvite}
              component={WelcomeWithCredits}
              options={{
                title: '',
                headerLeft: () => null,
              }}
            />
          </>
        );
      }
    }
  };

  const onHelpClicked = useCallback(() => {
    navigation.navigate('help', { screen: 'Help' }); // TODO: Navigate to FAQ?
  }, [navigation]);
  const bwCreds: BitwardsCredentials | undefined = useMemo(() => {
    if (Constants.manifest?.extra?.disableBitwards) {
      return undefined;
    }
    return { type: 'basic', username: bitwardSuperUsername as string, password: bitwardSuperUserPassword as string };
  }, []);
  return (
    <ActivePaymentProvider>
      <MapViewFilterProvider>
        <BitwardsProvider credentials={bwCreds} translate={I18n.t} onHelpClicked={onHelpClicked}>
          <MapViewBannerProvider>
            {/* eslint-disable react/style-prop-object */}
            <StatusBar style="light" translucent={true} backgroundColor={'transparent'} />
            <ForceUpdate>
              <Stack.Navigator
                screenOptions={{
                  headerStyle: {
                    backgroundColor: colors.surface,
                    // This is the default header height of react-native-paper appbars
                    height: 56 + top,
                  },
                  headerMode: 'screen',
                  headerTintColor: colors.secondary,
                  headerTitleStyle: {
                    fontWeight: '400',
                    fontFamily: fonts.default.fontFamily,
                    fontSize: 20,
                  },
                  headerBackground: () => <View style={{ flex: 1, backgroundColor: colors.surface }} />,
                  headerBackImage: () => (
                    <UiIcon
                      set={'material'}
                      name={'arrow-back-ios'}
                      size={20}
                      color={colors.secondary}
                      style={{ marginLeft: 10 }}
                    />
                  ),
                  headerBackTitle: '',
                  headerBackTitleStyle: {
                    display: 'none',
                  },
                  headerRightContainerStyle: { marginRight: 10 },
                }}
              >
                {renderRoute()}
              </Stack.Navigator>
            </ForceUpdate>
          </MapViewBannerProvider>
        </BitwardsProvider>
      </MapViewFilterProvider>
    </ActivePaymentProvider>
  );
}
