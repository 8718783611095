import chroma from 'chroma-js';
import { BaseMapFullTheme } from './OSMLibreLayers';

/* 
Top level: Define colors in BaseMapSimpleTheme type object.
Next level: Applies colors and creates paint and layout rules object of type BaseMapFullTheme
Finally: Create layers, by taking BaseMapFullTheme properties and returning the layer list.


BaseMapSimpleTheme: Just colors
 -  Easy to make dark mode, calm or high contrast map
BaseMapFullTheme: All pain trules, grouped by high level object, like motorway
 -  Easy to style an object, like if I want the motorway to be thicker, I don't need to hunt for the 6 different styles, as they are grouped under motorway.
Layers: Actual Maplibre layers. 
 - Just defines filter and rendering order.
 
*/

export type BaseMapSimpleTheme = {
  land: {
    default: string;
    residential: string;
    wood: string;
    grass: string;
    ice: string;
    cemetery: string;
    hospital: string;
    school: string;
    sand: string;
    park: string;
  };
  water: string;
  aeroway: string;
  pedestrian: string;
  rail: string;
  road: {
    motorway: string;
    primary: string;
    secondary: string;
    street: string;
    track: string;
    casings: string;
  };
  building: string;
  building3d: string;
  boundaries: string;
  poiText: string;
  poiHalo: string;
  placeText: string;
  placeHalo: string;
};

const lighten = (color: string, hslPercent: number): string =>
  chroma(color)
    .set('hsl.l', chroma(color).get('hsl.l') + hslPercent)
    .hex();
const darken = (color: string, hslPercent: number): string => lighten(color, -hslPercent);

export function generateTheme(colors: BaseMapSimpleTheme): BaseMapFullTheme {
  /* 
water: string;
  aeroway: string;
  pedestrian: string;
  rail: string;
  road: {
    motorway: string;
    primary: string;
    secondary: string;
    street: string;
    track: string;
    casings: string;
  };
  */
  const widths = {
    roads: {
      motorwayZ20: 26,
      motorwayLinkZ20: 12,
      trackZ20: 8,
      streetZ20: 18,
      primaryZ20: 24,
      secondaryZ20: 24,
    },
  };
  return {
    land: {
      default: { 'background-color': colors.land.default },
      residential: {
        fill: { 'fill-color': colors.land.residential },
      },
      wood: {
        fill: {
          'fill-antialias': false,
          'fill-color': colors.land.wood,
          'fill-opacity': 0.4,
        },
      },
      grass: {
        fill: {
          'fill-antialias': false,
          'fill-color': colors.land.grass,
          'fill-opacity': 0.3,
        },
      },
      ice: {
        fill: {
          'fill-antialias': false,
          'fill-color': colors.land.ice,
          'fill-opacity': 0.8,
        },
      },
      cemetery: {
        fill: { 'fill-color': colors.land.cemetery },
      },
      hospital: {
        fill: { 'fill-color': colors.land.hospital },
      },
      school: {
        fill: { 'fill-color': colors.land.school },
      },
      sand: {
        fill: { 'fill-color': colors.land.sand },
      },
      park: {
        fill: {
          'fill-color': colors.land.park,
          'fill-opacity': 0.7,
          'fill-outline-color': darken(colors.land.park, 0.2),
        },
      },
    },
    water: {
      default: {
        fill: { 'fill-color': colors.water },
        labelPoint: {
          layout: {
            'text-field': '{name}',
            'text-font': ['Roboto Regular'],
            'text-max-width': 5,
            'text-size': 12,
          },
          paint: {
            'text-color': colors.placeText,
            'text-halo-color': colors.placeHalo,
            'text-halo-width': 1,
          },
        },

        labelAlong: {
          layout: {
            'text-field': '{name}',
            'text-font': ['Roboto Regular'],
            'text-max-width': 5,
            'text-size': 12,
            'symbol-placement': 'line',
          },
          paint: {
            'text-color': colors.placeText,
            'text-halo-color': colors.placeHalo,
            'text-halo-width': 1,
          },
        },
      },

      tunnel: {
        line: {
          'line-color': darken(colors.water, 0.2),
          'line-dasharray': [3, 3],
          'line-gap-width': {
            stops: [
              [12, 0],
              [20, 6],
            ],
          },
          'line-opacity': 1,
          'line-width': {
            base: 1.4,
            stops: [
              [8, 1],
              [20, 2],
            ],
          },
        },
      },
      river: {
        line: {
          'line-color': colors.water,
          'line-width': {
            base: 1.2,
            stops: [
              [11, 0.5],
              [20, 6],
            ],
          },
        },
      },
      way: {
        line: {
          'line-color': colors.water,
          'line-width': {
            base: 1.3,
            stops: [
              [13, 0.5],
              [20, 6],
            ],
          },
        },
      },
    },
    aeroway: {
      fill: { 'fill-color': colors.aeroway, 'fill-opacity': 0.7 },
      runway: {
        line: {
          'line-color': colors.aeroway,
          'line-width': {
            base: 1.2,
            stops: [
              [11, 3],
              [20, 16],
            ],
          },
        },
      },
      taxiway: {
        line: {
          'line-color': colors.aeroway,
          'line-width': {
            base: 1.2,
            stops: [
              [11, 0.5],
              [20, 6],
            ],
          },
        },
      },
    },

    pedestrian: {
      way: {
        line: {
          'line-color': colors.pedestrian,
          'line-dasharray': [1, 2],
          'line-width': 1.5,
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 14.0, 0, 14.5, 0.8],
        },
      },
      tunnel: {
        line: {
          'line-color': colors.pedestrian,
          'line-dasharray': [1, 0.75],
          'line-width': {
            base: 1.2,
            stops: [
              [14, 0.5],
              [20, 10],
            ],
          },
        },
      },
      bridge: {
        line: {
          'line-color': lighten(colors.pedestrian, 0.2),
          'line-dasharray': [1, 0.3],
          'line-width': {
            base: 1.2,
            stops: [
              [14, 0.5],
              [20, 10],
            ],
          },
        },
        casing: {
          'line-color': darken(colors.pedestrian, 0.3),
          'line-dasharray': [1, 0],
          'line-width': {
            base: 1.2,
            stops: [
              [14, 1.5],
              [20, 18],
            ],
          },
        },
      },
    },
    rail: {
      major: {
        way: {
          line: {
            'line-color': colors.rail,
            'line-width': {
              base: 1.4,
              stops: [
                [14, 0.4],
                [15, 0.75],
                [20, 2],
              ],
            },
          },
          hatching: {
            'line-color': colors.rail,
            'line-dasharray': [0.2, 8],
            'line-width': {
              base: 1.4,
              stops: [
                [14.5, 0],
                [15, 3],
                [20, 8],
              ],
            },
          },
        },
        tunnel: {
          line: {
            'line-color': darken(colors.rail, 0.2),
            'line-width': {
              base: 1.4,
              stops: [
                [14, 0.4],
                [15, 0.75],
                [20, 2],
              ],
            },
          },
          hatching: {
            'line-color': darken(colors.rail, 0.2),
            'line-dasharray': [0.2, 8],
            'line-width': {
              base: 1.4,
              stops: [
                [14.5, 0],
                [15, 3],
                [20, 8],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.rail, 0.1),
            'line-width': {
              base: 1.4,
              stops: [
                [14, 0.4],
                [15, 0.75],
                [20, 2],
              ],
            },
          },
          hatching: {
            'line-color': lighten(colors.rail, 0.1),
            'line-dasharray': [0.2, 8],
            'line-width': {
              base: 1.4,
              stops: [
                [14.5, 0],
                [15, 3],
                [20, 8],
              ],
            },
          },
        },
      },
      transit: {
        way: {
          line: {
            'line-color': colors.rail,
            'line-width': {
              base: 1.4,
              stops: [
                [14, 0.4],
                [15, 0.75],
                [20, 2],
              ],
            },
          },
          hatching: {
            'line-color': colors.rail,
            'line-dasharray': [0.2, 8],
            'line-width': {
              base: 1.4,
              stops: [
                [14.5, 0],
                [15, 3],
                [20, 8],
              ],
            },
          },
        },
        tunnel: {
          line: {
            'line-color': darken(colors.rail, 0.1),
            'line-width': {
              base: 1.4,
              stops: [
                [14, 0.4],
                [15, 0.75],
                [20, 2],
              ],
            },
          },
          hatching: {
            'line-color': darken(colors.rail, 0.1),
            'line-dasharray': [0.2, 8],
            'line-width': {
              base: 1.4,
              stops: [
                [14.5, 0],
                [15, 3],
                [20, 8],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.rail, 0.1),
            'line-width': {
              base: 1.4,
              stops: [
                [14, 0.4],
                [15, 0.75],
                [20, 2],
              ],
            },
          },
          hatching: {
            'line-color': lighten(colors.rail, 0.1),
            'line-dasharray': [0.2, 8],
            'line-width': {
              base: 1.4,
              stops: [
                [14.5, 0],
                [15, 3],
                [20, 8],
              ],
            },
          },
        },
      },
    },
    road: {
      default: {
        fill: {
          /* "fill-pattern": "pedestrian_polygon" */
          'fill-color': colors.land.default,
        },
        oneway: {
          layout: {
            /* "icon-image": "arrow", "symbol-placement": "line" */
          },
        },
        labelAlong: {
          layout: {
            'symbol-placement': 'line',
            'text-anchor': 'center',
            'text-field': '{name}',
            'text-font': ['Roboto Regular'],
            'text-offset': [0, 0.15],
            'text-size': {
              base: 1,
              stops: [
                [13, 12],
                [14, 13],
              ],
            },
          },
          paint: {
            'text-color': colors.poiText,
            'text-halo-blur': 0.5,
            'text-halo-width': 1,
            'text-halo-color': colors.poiHalo,
          },
        },
        labelPoint: {
          paint: {},
          layout: {
            // "icon-image": "default_{ref_length}",
            'icon-rotation-alignment': 'viewport',
            'symbol-placement': 'line', // {"base": 1, "stops": [[10, "point"], [11, "line"]]}, // TODO: Zoomed in vs zoomed out
            'symbol-spacing': 500,
            'text-field': '{ref}',
            'text-font': ['Roboto Regular'],
            'text-offset': [0, 0.1],
            'text-rotation-alignment': 'viewport',
            'text-size': 10,
            'icon-size': 0.8,
          },
        },
        otherway: {
          layout: {
            // "icon-image": "arrow",
            'symbol-placement': 'line',
            'icon-rotate': 180,
          },
        },
      },
      otherLink: {
        way: {
          line: {
            'line-color': colors.road.primary,
            'line-width': {
              base: 1.2,
              stops: [
                [12.5, 0],
                [13, 1.5],
                [14, 2.5],
                [20, 11.5],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [12, 1],
                [13, 3],
                [14, 4],
                [20, 15],
              ],
            },
          },
        },
        tunnel: {
          line: {
            'line-color': darken(colors.road.primary, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [12.5, 0],
                [13, 1.5],
                [14, 2.5],
                [20, 11.5],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [12, 1],
                [13, 3],
                [14, 4],
                [20, 15],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.road.primary, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [12.5, 0],
                [13, 1.5],
                [14, 2.5],
                [20, 11.5],
              ],
            },
          },
          casing: {
            'line-color': darken(colors.road.casings, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [12, 1],
                [13, 3],
                [14, 4],
                [20, 15],
              ],
            },
          },
        },
      },
      motorway: {
        way: {
          line: {
            'line-color': {
              base: 1,
              stops: [
                [5, colors.road.motorway],
                [6, chroma(colors.road.motorway).desaturate(0.2).hex()],
              ],
            },
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0],
                [7, 1],
                [20, widths.roads.motorwayZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0.4],
                [6, 0.7],
                [7, 1.75],
                [20, widths.roads.motorwayZ20 + 2],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.road.motorway, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0],
                [7, 1],
                [20, widths.roads.motorwayZ20],
              ],
            },
          },
          casing: {
            'line-color': darken(colors.road.casings, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0.4],
                [6, 0.7],
                [7, 1.75],
                [20, widths.roads.motorwayZ20 + 2],
              ],
            },
          },
        },

        tunnel: {
          line: {
            'line-color': darken(colors.road.motorway, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0],
                [7, 1],
                [20, widths.roads.motorwayZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-dasharray': [0.5, 0.25],
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0.4],
                [6, 0.7],
                [7, 1.75],
                [20, widths.roads.motorwayZ20 + 2],
              ],
            },
          },
        },
      },
      motorwayLink: {
        way: {
          line: {
            'line-color': colors.road.motorway,
            'line-width': {
              base: 1.2,
              stops: [
                [12.5, 0],
                [13, 1.5],
                [14, 2.5],
                [20, widths.roads.motorwayLinkZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [12, 1],
                [13, 3],
                [14, 4],
                [20, widths.roads.motorwayLinkZ20 + 2],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.road.motorway, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [12.5, 0],
                [13, 1.5],
                [14, 2.5],
                [20, widths.roads.motorwayLinkZ20],
              ],
            },
          },
          casing: {
            'line-color': darken(colors.road.casings, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [12, 1],
                [13, 3],
                [14, 4],
                [20, widths.roads.motorwayLinkZ20 + 2],
              ],
            },
          },
        },
        tunnel: {
          line: {
            'line-color': darken(colors.road.motorway, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [12.5, 0],
                [13, 1.5],
                [14, 2.5],
                [20, widths.roads.motorwayLinkZ20 - 2],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-dasharray': [0.5, 0.25],
            'line-width': {
              base: 1.2,
              stops: [
                [12, 1],
                [13, 3],
                [14, 4],
                [20, widths.roads.motorwayLinkZ20 - 2 + 2],
              ],
            },
          },
        },
      },
      track: {
        way: {
          line: {
            'line-color': colors.road.track,
            'line-width': {
              base: 1.2,
              stops: [
                [14, 0],
                [15, 1],
                [16, 2],
                [20, widths.roads.trackZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [15, 0],
                [16, 4],
                [20, widths.roads.trackZ20 + 2],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.road.track, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [14, 0],
                [15.5, 0],
                [16, 2],
                [20, widths.roads.trackZ20],
              ],
            },
          },
          casing: {
            'line-color': darken(colors.road.casings, 1),
            'line-width': {
              base: 1.2,
              stops: [
                [14, 0],
                [15, 1],
                [16, 4],
                [20, widths.roads.trackZ20 + 2],
              ],
            },
          },
        },
        tunnel: {
          line: {
            'line-color': darken(colors.road.track, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [15.5, 0],
                [16, 2],
                [20, widths.roads.trackZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-dasharray': [0.5, 0.25],
            'line-width': {
              base: 1.2,
              stops: [
                [14, 0],
                [15, 1],
                [16, 4],
                [20, widths.roads.trackZ20 + 2],
              ],
            },
          },
        },
      },
      street: {
        way: {
          line: {
            'line-color': colors.road.street,
            'line-width': {
              base: 1.2,
              stops: [
                [12, 0],
                [13, 3],
                [20, widths.roads.streetZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-opacity': {
              stops: [
                [12, 0],
                [12.5, 1],
              ],
            },
            'line-width': {
              base: 1.2,
              stops: [
                [12, 0],
                [13, 1],
                [20, widths.roads.streetZ20 + 2],
              ],
            },
          },
        },
        tunnel: {
          line: {
            'line-color': darken(colors.road.street, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [13.5, 0],
                [14, 2.5],
                [20, widths.roads.streetZ20 - 2],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-opacity': {
              stops: [
                [12, 0],
                [12.5, 1],
              ],
            },
            'line-width': {
              base: 1.2,
              stops: [
                [12, 0.5],
                [13, 1],
                [14, 4],
                [20, widths.roads.streetZ20 - 2 + 2],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.road.street, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [13.5, 0],
                [14, 2.5],
                [20, widths.roads.streetZ20],
              ],
            },
          },
          casing: {
            'line-color': darken(colors.road.casings, 0.1),
            'line-opacity': {
              stops: [
                [12, 0],
                [12.5, 1],
              ],
            },
            'line-width': {
              base: 1.2,
              stops: [
                [12, 0.5],
                [13, 1],
                [14, 4],
                [20, widths.roads.streetZ20 + 2],
              ],
            },
          },
        },
      },
      secondary: {
        way: {
          line: {
            'line-color': colors.road.secondary,
            'line-width': {
              base: 1.2,
              stops: [
                [6.5, 0],
                [8, 0.5],
                [20, widths.roads.secondaryZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [8, 1.5],
                [20, widths.roads.secondaryZ20 + 2],
              ],
            },
          },
        },
        tunnel: {
          line: {
            'line-color': darken(colors.road.secondary, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [6.5, 0],
                [7, 0.5],
                [20, widths.roads.secondaryZ20 - 2],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [8, 1.5],
                [20, widths.roads.secondaryZ20 - 2 + 2],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.road.secondary, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [6.5, 0],
                [7, 0.5],
                [20, widths.roads.secondaryZ20],
              ],
            },
          },
          casing: {
            'line-color': darken(colors.road.casings, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [8, 1.5],
                [20, widths.roads.secondaryZ20 + 2],
              ],
            },
          },
        },
      },
      primary: {
        way: {
          line: {
            'line-color': colors.road.primary,
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0],
                [7, 1],
                [20, widths.roads.primaryZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0.4],
                [6, 0.7],
                [7, 1.75],
                [20, widths.roads.primaryZ20 + 2],
              ],
            },
          },
        },
        tunnel: {
          line: {
            'line-color': darken(colors.road.primary, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0],
                [7, 1],
                [20, widths.roads.primaryZ20 - 2],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0.4],
                [6, 0.7],
                [7, 1.75],
                [20, widths.roads.primaryZ20 - 2 + 2],
              ],
            },
          },
        },
        bridge: {
          line: {
            'line-color': lighten(colors.road.primary, 0.1),
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0],
                [7, 1],
                [20, widths.roads.primaryZ20],
              ],
            },
          },
          casing: {
            'line-color': colors.road.casings,
            'line-width': {
              base: 1.2,
              stops: [
                [5, 0.4],
                [6, 0.7],
                [7, 1.75],
                [20, widths.roads.primaryZ20 + 2],
              ],
            },
          },
        },
      },
    },
    building: {
      fill: {
        'fill-color': colors.building,
        'fill-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [15, 1],
          ],
        },
        'fill-outline-color': {
          base: 1,
          stops: [
            [13, colors.building],
            [14, darken(colors.building, 0.2)],
          ],
        },
      },
      fill3d: {
        'fill-extrusion-color': colors.building3d,
        'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 14.5, 0, 14.75, ['get', 'render_height']],
        'fill-extrusion-base': {
          property: 'render_min_height',
          type: 'identity',
        },
        'fill-extrusion-opacity': ['interpolate', ['linear'], ['zoom'], 14.5, 0, 14.75, 0.8],
      },
    },
    boundaries: {
      level3: {
        line: {
          'line-color': colors.boundaries,
          'line-dasharray': [5, 1],
          'line-width': {
            base: 1,
            stops: [
              [4, 0.4],
              [5, 1],
              [12, 1.8],
            ],
          },
        },
      },
      level2: {
        zoomedOut: {
          line: {
            'line-color': colors.boundaries,
            'line-opacity': {
              base: 1,
              stops: [
                [0, 0.4],
                [4, 1],
              ],
            },
            'line-width': {
              base: 1,
              stops: [
                [3, 1],
                [5, 1.2],
                [12, 3],
              ],
            },
          },
        },
        zoomedIn: {
          line: {
            'line-color': colors.boundaries,
            'line-opacity': {
              base: 1,
              stops: [
                [0, 0.4],
                [4, 1],
              ],
            },
            'line-width': {
              base: 1,
              stops: [
                [3, 1],
                [5, 1.2],
                [12, 3],
              ],
            },
          },
        },
      },
    },
    poi: {
      rank20: {
        labelPoint: {
          layout: {
            visibility: 'none',
            // "icon-image": "{class}_11",
            'text-anchor': 'top',
            'text-field': '{name}',
            'text-font': ['Roboto Condensed Italic'],
            'text-max-width': 9,
            'text-offset': [0, 0.6],
            'text-size': 12,
          },
          paint: {
            'text-color': colors.poiText,
            'text-halo-blur': 0.5,
            'text-halo-color': colors.poiHalo,
            'text-halo-width': 1,
          },
        },
      },
      rank7_20: {
        labelPoint: {
          layout: {
            visibility: 'none',
            // "icon-image": "{class}_11",
            'text-anchor': 'top',
            'text-field': '{name}',
            'text-font': ['Roboto Condensed Italic'],
            'text-max-width': 9,
            'text-offset': [0, 0.6],
            'text-size': 12,
          },
          paint: {
            'text-color': colors.poiText,
            'text-halo-blur': 0.5,
            'text-halo-color': colors.poiHalo,
            'text-halo-width': 1,
          },
        },
      },
      rank1_6: {
        labelPoint: {
          layout: {
            visibility: 'none',
            // "icon-image": "{class}_11",
            'text-anchor': 'top',
            'text-field': '{name}',
            'text-font': ['Roboto Condensed Italic'],
            'text-max-width': 9,
            'text-offset': [0, 0.6],
            'text-size': 12,
          },
          paint: {
            'text-color': colors.poiText,
            'text-halo-blur': 0.5,
            'text-halo-color': colors.poiHalo,
            'text-halo-width': 1,
          },
        },
      },
      transit: {
        labelPoint: {
          layout: {
            // "icon-image": "{class}_11",
            'text-anchor': 'left',
            'text-field': '{name_en}',
            'text-font': ['Roboto Condensed Italic'],
            'text-max-width': 9,
            'text-offset': [0.9, 0],
            'text-size': 12,
          },
          paint: {
            'text-color': colors.poiText,
            'text-halo-blur': 0.5,
            'text-halo-color': colors.poiHalo,
            'text-halo-width': 1,
          },
        },
      },
    },
    place: {
      default: {
        labelPoint: {
          layout: {
            'text-field': '{name_en}',
            'text-font': ['Roboto Condensed Italic'],
            'text-letter-spacing': 0.1,
            'text-max-width': 9,
            'text-padding': 25, // There are a lot of random places, and I don't want to show all of them at the same time
            'text-size': {
              base: 1.2,
              stops: [
                [12, 10],
                [15, 14],
              ],
            },
            'text-transform': 'uppercase',
          },
          paint: {
            'text-color': colors.placeText,
            'text-halo-color': colors.placeHalo,
            'text-halo-width': 1.2,
          },
        },
      },
      village: {
        labelPoint: {
          layout: {
            'text-field': '{name_en}',
            'text-font': ['Roboto Regular'],
            'text-max-width': 8,
            'text-size': {
              base: 1.2,
              stops: [
                [10, 12],
                [15, 22],
              ],
            },
          },
          paint: {
            'text-color': colors.placeText,
            'text-halo-color': colors.placeHalo,
            'text-halo-width': 1.2,
          },
        },
      },
      town: {
        labelPoint: {
          layout: {
            /* 
            "icon-image": {
              base: 1,
              stops: [
                [0, "dot_9"],
                [8, ""],
              ],
            },
            */
            'text-anchor': 'bottom',
            'text-field': '{name_en}',
            'text-font': ['Roboto Regular'],
            'text-max-width': 8,
            'text-offset': [0, 0],
            'text-size': {
              base: 1.2,
              stops: [
                [7, 12],
                [11, 16],
              ],
            },
          },
          paint: {
            'text-color': colors.placeText,
            'text-halo-color': colors.placeHalo,
            'text-halo-width': 1.2,
          },
        },
      },
      city: {
        labelPoint: {
          layout: {
            /* 
            "icon-image": {
              base: 1,
              stops: [
                [0, "dot_9"],
                [8, ""],
              ],
            },
            */
            'text-anchor': 'bottom',
            'text-field': '{name_en}',
            'text-font': ['Roboto Medium'],
            'text-max-width': 8,
            'text-offset': [0, 0],
            'text-size': {
              base: 1.2,
              stops: [
                [7, 14],
                [11, 24],
              ],
            },
            'icon-allow-overlap': true,
            'icon-optional': false,
          },
          paint: {
            'text-color': colors.placeText,
            'text-halo-color': colors.placeHalo,
            'text-halo-width': 1.2,
          },
        },
      },
      state: {
        labelPoint: {
          layout: {
            'text-field': '{name_en}',
            'text-font': ['Roboto Condensed Italic'],
            'text-size': {
              stops: [
                [4, 11],
                [6, 15],
              ],
            },
            'text-transform': 'uppercase',
          },
          paint: {
            'text-color': colors.placeText,
            'text-halo-color': colors.placeHalo,
            'text-halo-width': 1,
          },
        },
      },
      country: {
        rank3: {
          labelPoint: {
            layout: {
              'text-field': '{name_en}',
              'text-font': ['Roboto Condensed Italic'],
              'text-max-width': 6.25,
              'text-size': {
                stops: [
                  [3, 11],
                  [7, 17],
                ],
              },
              'text-transform': 'none',
            },
            paint: {
              'text-color': colors.placeText,
              'text-halo-blur': 1,
              'text-halo-color': colors.placeHalo,
              'text-halo-width': 1,
            },
          },
        },
        rank2: {
          labelPoint: {
            layout: {
              'text-field': '{name_en}',
              'text-font': ['Roboto Condensed Italic'],
              'text-max-width': 6.25,
              'text-size': {
                stops: [
                  [2, 11],
                  [5, 17],
                ],
              },
              'text-transform': 'none',
            },
            paint: {
              'text-color': colors.placeText,
              'text-halo-blur': 1,
              'text-halo-color': colors.placeHalo,
              'text-halo-width': 1,
            },
          },
        },
        rank1: {
          labelPoint: {
            layout: {
              'text-field': '{name_en}',
              'text-font': ['Roboto Condensed Italic'],
              'text-max-width': 6.25,
              'text-size': {
                stops: [
                  [1, 11],
                  [4, 17],
                ],
              },
              'text-transform': 'none',
            },
            paint: {
              'text-color': colors.placeText,
              'text-halo-blur': 1,
              'text-halo-color': colors.placeHalo,
              'text-halo-width': 1,
            },
          },
        },
      },
      continent: {
        labelPoint: {
          layout: {
            'text-field': '{name_en}',
            'text-font': ['Roboto Condensed Italic'],
            'text-size': 13,
            'text-transform': 'uppercase',
            'text-justify': 'center',
          },
          paint: {
            'text-color': colors.placeText,
            'text-halo-color': colors.placeHalo,
            'text-halo-width': 1,
          },
        },
      },
    },
  };
}

export function getLightTheme() {
  return generateTheme({
    land: {
      default: 'rgb(241,241,241)',
      residential: '#f1f1f1',
      wood: '#a6bfa5',
      grass: '#a6bfa5',
      ice: '#f1f1f1',
      cemetery: '#dddddd',
      hospital: '#dddddd',
      school: '#dddddd',
      sand: '#f1f1f1',
      park: '#a6bfa5'
    },
    water: '#afd1db',
    aeroway: '#ffffff',
    pedestrian: '#ffffff',
    rail: '#ffffff',
    road: {
      motorway: '#ffffff',
      primary: '#ffffff',
      secondary: '#ffffff',
      street: '#ffffff',
      track: '#ffffff',
      casings: '#ffffff'
    },
    building: '#dddddd',
    building3d: '#dddddd',
    boundaries: '#b2b2b2',
    poiText: '#696969',
    poiHalo: '#ffffff',
    placeText: '#696969',
    placeHalo: '#ffffff'
  });
}
export function getDarkTheme() {
  const road = '#4e6869';
  const greens = '#2f522b';
  const buildings = '#40536b';
  const texts = '#cfcfcf';
  const textHalos = '#212121';

  return generateTheme({
    land: {
      default: 'rgb(0,0,0)',
      residential: '#0d0c0c',
      wood: greens,
      grass: greens,
      ice: '#1a1a1a',
      cemetery: '#1a1b18',
      hospital: '#1b181a',
      school: '#1b1b18',
      sand: '#2c2b21',
      park: greens,
    },
    water: '#3f3f4f',
    aeroway: '#4d4d4d',
    pedestrian: road,
    rail: road,
    road: {
      motorway: road,
      primary: road,
      secondary: road,
      street: road,
      track: road,
      casings: road,
    },
    building: buildings,
    building3d: buildings,
    boundaries: '#7d7d7d',
    poiText: texts,
    poiHalo: textHalos,
    placeText: texts,
    placeHalo: textHalos,
  });
}
