import type { Layer, VectorSource } from 'maplibre-gl';
import type maplibregl from 'maplibre-gl';

export type FloorSwitchingVars = {
  buildingRef: string;
  layerIndex: number;
};

const SwitchFloors = (vars: FloorSwitchingVars, basicMap: maplibregl.Map, lib: typeof maplibregl, _window: Window) => {
  const sourceId = 'biketti_floor_tiles';

  /* We will extend the map a bit */
  const map = basicMap as maplibregl.Map & {
    biketti?: {
      floorSwitching?: { floorTileUrlTemplate: string | undefined };
    };
  };

  const updateTileSourceUrl = () => {
    const floorTileSource: VectorSource = map.getSource(sourceId) as VectorSource;

    /* Step x: Get the floor based tile url */
    let urlTemplate: string | undefined = map.biketti?.floorSwitching?.floorTileUrlTemplate;
    if (!urlTemplate) {
      urlTemplate = floorTileSource.tiles?.length ? floorTileSource.tiles[0] : undefined;

      if (!map.biketti)
        map.biketti = {
          floorSwitching: {
            floorTileUrlTemplate: urlTemplate,
          },
        };
      if (!map.biketti.floorSwitching) map.biketti.floorSwitching = { floorTileUrlTemplate: urlTemplate };
    }
    // debugger;
    if (!urlTemplate) {
      return false;
    }
    const newSourceUrl = urlTemplate.replace('{f}', '' + vars.layerIndex);
    if (floorTileSource.tiles?.length && floorTileSource.tiles[0] === newSourceUrl) {
      return false;
    }
    /* Step x: Update it to have the new index */
    floorTileSource.tiles = [newSourceUrl];

    /* Clear the pre-rendered tiles from cache */
    (map as any).style.sourceCaches[sourceId].clearTiles();

    return true;
  };

  const findFilterMatching = (matcher: [string, string], filter: any[]): any[] | undefined => {
    /* Check if this one matches */
    if (filter.length > 2 && filter[0] === matcher[0] && filter[1] === matcher[1]) {
      return filter;
    }

    for (let i = 0; i < filter.length; i++) {
      if (Array.isArray(filter[i])) {
        const matchingFilter = findFilterMatching(matcher, filter[i]);
        if (matchingFilter) return matchingFilter;
      }
    }
  };

  const updateFilter = (filter?: any[]) => {
    let didUpdate = false;
    if (!filter) return didUpdate;

    const buildingFilt = findFilterMatching(['==', 'buildingRef'], filter);
    const levelFilt = findFilterMatching(['==', 'layerIndex'], filter);
    if (!buildingFilt || !levelFilt) return false;

    if (buildingFilt && buildingFilt[2] !== vars.buildingRef) {
      buildingFilt[2] = vars.buildingRef;
      didUpdate = true;
    }
    if (levelFilt && levelFilt[2] !== vars.layerIndex) {
      levelFilt[2] = vars.layerIndex;
      didUpdate = true;
    }
    return didUpdate;
  };

  const updateStyleFilters = () => {
    let didUpdate = false;
    const newStyle = JSON.parse(JSON.stringify(map.getStyle())) as maplibregl.Style;
    newStyle.layers?.forEach((l) => {
      didUpdate = updateFilter((l as Layer).filter) || didUpdate;
    });
    if (didUpdate) {
      map.setStyle(newStyle, {
        diff: true,
      });
    }
    return didUpdate;
  };

  let didUpdate = false;
  didUpdate = updateTileSourceUrl() || didUpdate;
  didUpdate = updateStyleFilters() || didUpdate;

  /* Force a repaint, so that the map will be repainted without you having to touch the map */
  if (didUpdate) {
    /* Load the new tiles for the current viewport (map.transform -> viewport) */
    (map as any).style.sourceCaches[sourceId].update((map as any).transform);

    /* Force a repaint, so that the map will be repainted without you having to touch the map */
    map.triggerRepaint();
  }
};

export default SwitchFloors;
